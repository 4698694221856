@import "../../../../styles/main.scss";

.mentor-hero-container {
  // background: $plale-black-color;

  background: url("../../../../assets/images/heroSection/Mentor_BG.png") no-repeat;
  background-size: cover; 
  height: auto;
  padding: 32px 0;
  @include media-query(phone, tablet) {
    height: auto;
    padding-top: 10%;
  }
}

.hero-illust {
  max-width: 400px;
  margin: 0 auto;
}

