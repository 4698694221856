@import "../../../../styles/main.scss";

.heading {
  font-family: 'basetica-bold';
  color: #515255;
  font-size: 22px;
  padding: 12px 0px;
  text-align: center;
  @include media-query(phone, tablet) {
    font-size: 16px;
    line-height: 20px;
    padding:  0px;
  }
}
