.container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.img {
  height: 67px;
  width: 67px;
}
