@import "../../../../../styles/main.scss";

.wrapper {
  background-color: white;

  .container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    padding: 48px 0;
    color: #515255;
    align-self: center;

    @include media-query(phone, tablet) {
      padding: 30px 0px;
      flex-direction: column;
      gap: 20px;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 7;
      .heading {
        font-size: 20px;
        font-weight: $bold;
      }

      .subText {
        font-size: 14px;
        font-weight: $regular;
        line-height: 1.2;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      flex: 3;

      @include media-query(phone, tablet) {
        justify-content: start;
        font-size: 14px;
      }

      .edit {
        border: 1px solid grey;
        outline: none;
        background-color: white;
        color: #515255;
        border-radius: 6px;
      }

      .retryPayment {
        border: 1px solid grey;
        outline: none;
        background: grey;
        color: white;
        border-radius: 6px;
      }
    }
  }
}
