.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: var(--height);
  display: flex;
  align-items: center;
}

.wrapper {
  position: absolute;
  display: flex;
}

.section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
