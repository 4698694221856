@import "../../../styles/main.scss";

/* Set a background color and center content vertically */
.tableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the table */
.table {
  border-collapse: collapse;
  width: 80%;
  margin: 20px auto;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: basetica-regular;
  text-align: center;

}

/* Style for table header */
.table thead th {
  background-color: #f2f2f2;
  border-bottom: 2px solid #ccc;
  padding: 10px;
  text-align: left;
  font-family: basetica-bold;
  text-align: center;
}

/* Style for table cells */
.table th,
.table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

/* Alternate row background color */
.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Highlight on hover */
.table tbody tr:hover {
  background-color: #e0e0e0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: 49.58px;
  margin-top: 10.72px;

  @include media-query(phone, tablet) {
    margin-left: 5px;
  }
}