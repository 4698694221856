.form-wrap {
  .form-row { 
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .btn-large {
      max-width: 360px;
      @include media-query(phone) {
        max-width: 200px;
      }
      .btn {
        width: 100%;
      }
    }
  }
}

.mobile-country-wrap {
  display: flex;
  align-items: center;
  color: $black-color;
  font-size: 16px;
  font-family: $regular;
  border-right: 1px solid $light-grey-color;
  padding-right: 24px;
  img {
    width: 24px;
    margin-right: 4px;
  }
  ~ input {
    padding-left: 14px;
  }
}

.field-verified-wrap {
  color: $green-color;
  font-family: $regular;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  img {
    margin-right: 6px;
  }
}

.MuiFormControl-root {
  width: 100%;
  .MuiFormLabel-root {
    font-size: 16px;
    font-family: $regular;
    color: $black-color;
    &.Mui-focused {
      color: $black-color;
    }
    &.Mui-error {
      color: $red-color;
    }
  }

  .MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: $light-grey-color;
      border-radius: 3px;
    }
    .MuiInputBase-input {
      font-size: 16px;
      font-family: $regular;
      color: $black-color;
    }
    &:hover {
      // border-color: rgba($color: $dark-grey-color, $alpha: 0.2);
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba($color: $black-color, $alpha: 0.4);
      }
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $black-color;
      border-width: 1px;
    }
    &.Mui-disabled {
      border-color: rgba($color: $black-color, $alpha: 0.4);
      background: $mud-pink-color;
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba($color: $black-color, $alpha: 0.4);
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $red-color;
        span {
          color: $red-color;
        }
      }
    }
  }
  .MuiInputBase-adornedStart {
    .MuiSvgIcon-root {
      font-size: 18px;
      margin-top: -3px;
    }
  }
  .MuiFormHelperText-root {
    font-size: 12px;
    font-family: $regular;
    color: $dark-grey-color;
    opacity: 0.6;
    &.Mui-error {
      color: $red-color;
      opacity: 1;
    }
  }
}

.helper-text {
  color: $dark-grey-color;
  opacity: 0.6;
  font-family: $regular;
  margin-left: 14px;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background: $primary-color !important;
}

.form-field-uppercase {
  input {
    text-transform: uppercase;
  }
}

.MuiAutocomplete-popper {
  z-index: 3;
}