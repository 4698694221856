@import "../../../../styles/main.scss";

.container {
  color: #515255;
  padding: 0 47px;
  font-family: basetica-thin;
  margin-bottom: 38px;
  line-height: 24px;
  font-size: 12px;

  @include media-query(phone, tablet) {
    padding: 0 20px;
    font-size: 14px;
    line-height: 18px;
  }

  .itemWrapper {
    display: flex;
    margin-top: 14px;
  }

  .marginNumber {
    font-family: basetica-regular;
    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: basetica-bold;
  }

  .item {
    margin-left: 20px;

    &::marker {
      font-family: basetica-regular;
    }
  }
}
