@import '../../styles/main.scss';

.capsule-wrap {
  display: inline-flex;
  &.mobile-column-wrap {
    @include media-query(phone) {
      flex-direction: column;
      align-items: center;
      .capsule-title { 
        margin-bottom: -1px;
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 1px solid;
        border-bottom: 0px;
        padding-right: 8px;
      }
    }
  }
  > * {
    padding: 8px;
    border-radius: 4px;
    line-height: 1;
    width: fit-content;
    text-transform: uppercase;
  }
  .capsule-title {
    border: 1px solid $slate-grey-color;
    background: $white-color;
    font-family: $bold;
    font-size: 14px;
    border-right: 0;
    margin-right: -3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 12px;
  }
  .capsule-subtitle {
    border: 1px solid $slate-grey-color;
    background: $white-color;
    font-family: $regular;
    font-size: 14px;
  }
  &.light-green-color {
    .capsule-subtitle {
      background-color: $light-green-color;
    }
  }
  &.light-torques-color {
    .capsule-subtitle {
      background-color: $light-torques-color;
    }
  }
  &.orange-color {
    .capsule-subtitle {
      background-color: $orange-color;
    }
  }
}