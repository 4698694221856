@import "../../../../styles/main.scss";

.container {
  display: flex;
  flex-wrap: wrap;
  font-family: "basetica-thin";
  margin: 29.48px 0;

  @include media-query(phone, tablet) {
    margin: 22.11px 0;
  }

  .dwebContainer {
    .row {
      display: flex;
  
      .row1Left {
        display: flex;
      }
  
      .row1Right {
        display: flex;
        flex-direction: column;
        max-width: 371.51px;
      }
  
      .row2Left {
        display: flex;
        flex-direction: column;
        max-width: 371.51px;
      }
      .row2Right {
        display: flex;
      }

      .row3 {
        display: flex;
      }
    }

    @include media-query(phone, tablet) {
      display: none;
    }
  }

  .mwebContainer {
    display: none;
    @include media-query(phone, tablet) {
      display: flex;
      flex-direction: column;
      gap: 5.36px;
    }
  }
}
