@each $property in ("m", "p") {
  @each $direction in ("t", "b", "l", "r") {
    @for $value from 0 through 10 {
      $class-name: ".#{$property}-#{$direction}-#{$value}";

      @at-root #{$class-name} {
        #{$property}: $value * 4px ;
      }
    }
  }
}
