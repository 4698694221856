.accordion-wrap {
  .MuiAccordion-root {
    box-shadow: none;
    box-shadow: 0px 3px 20px #FFB16429;
    border-radius: 6px;
    margin-bottom: 16px;
    .MuiButtonBase-root {
      padding: 0 36px;
      @include media-query(phone) {
        padding: 0 24px;
      }
      &.Mui-expanded {
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100% - 36px);
          height: 1px;
          background: $light-grey-color;
          @include media-query(phone) {
            width: calc(100% - 24px);
          }
        }
      }
    }
    &:before {
      display: none;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: $dark-grey-color;
    opacity: 0.7;
  }
  .MuiCollapse-root {
    padding: 0 16px;
    @include media-query(phone) {
      padding: 0 8px;
    }
  }
}