/* Flexbox utilities */
.d-flex {
  display: flex;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}
.flex-direction-column {
  flex-direction: column;
}
