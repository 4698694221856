@import "../../../../styles/main.scss";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 37px 0px;
  max-width: 857px;
  margin: 0 auto;
  color: #515255;

  @include media-query(phone, tablet) {
    font-size: 14px;
    line-height: 18px;
    padding: 55px 20px;
  }
}

.header {
  padding: 24px 0px;
  text-align: center;
  
  @include media-query(phone, tablet) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
}

.story {
  font-family: basetica-thin;
    
  max-width: 547px;
  color: rgba(81, 82, 85, 0.8);
  margin-bottom: 53px;
  font-size: 15px;
  line-height: 19px;
  // text-align: center;

  @include media-query(phone, tablet) {
    font-size: 14px;
    line-height: 18px;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

.problemAndSolution {
  max-width: 857px;
  display: flex;
  gap: 54px;
  font-family: basetica-thin;

  .divider {
    width: 1px;
    background: #ffded8;

    @include media-query(phone, tablet) {
      display: none;
    }
  }

  @include media-query(phone, tablet) {
    flex-direction: column;
    gap: 0;
  }

  .image {
    height: 114px;
    width: 114px;
    display: inline;
    @include media-query(phone, tablet) {
      height: 90px;
      width: 90px;
      margin-right: 15px;
    }
  }

  .heading {
    font-family: basetica-bold;
    padding: 21px 0px;
    font-size: 32px;
    color: #515255;
    @include media-query(phone, tablet) {
      font-size: 24px;
      padding: 8px 0;
    }
  }

  .problem {
    flex: 1;
    text-align: right;
    font-size: 15px;
    line-height: 19px;
    color: rgba(81, 82, 85, 0.8);
    @include media-query(phone, tablet) {
      display: flex;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .solution {
    padding-top: 67px;
    flex: 1;
    text-align: left;
    font-size: 15px;
    line-height: 19px;
    color: rgba(81, 82, 85, 0.8);
    @include media-query(phone, tablet) {
      display: flex;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      padding-top: 24px;
    }
  }
}
