@import "./../../../styles/main.scss";

.container {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  min-height: calc(100vh - 114px);
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-query(phone, tablet) {
    min-height: calc(100vh - 174px);
  }
}

.paymentFailedBox {
  width: 30%;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 48px;
  color: rgba(255, 0, 0, 0.8);
  font-family: basetica-bold;
  gap: 30px;

  @include media-query(phone, tablet) {
    width: 90%;
    height: 300px;
    font-size: 24px;
  }

  .warningIcon {
    height: 300px;
    width: 300px;

    @include media-query(phone, tablet) {
      height: 100px;
      width: 100px;
    }
  }

  .retryPayment {
    border: 1px solid grey;
    outline: none;
    background: grey;
    color: white;
    border-radius: 6px;

    &:hover {
      background: grey;
    }
  }
}