@import "../../../../styles/main.scss";

.container {
  padding: 37px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-query(phone, tablet) {
    display: block;
    padding: 33px 0;
  }
}

.benefitsCta {
  background: #ffb164;
  color: #1c1c1d;
  letter-spacing: 1.88px;
  padding: 8px 19px;
  border-radius: 4px;
  display: inline-block;
}

.heading {
  padding-top: 13px;
  text-align: center;

  @include media-query(phone, tablet) {
    padding-bottom: 6px;
    padding-top: 10px;
  }
}

.subHeading {
  text-align: center;
  padding-bottom: 21px;
  color: #515255;
  opacity: 0.7;
  max-width: 300px;
  line-height: 20px;
  font-size: 16px;
  margin: 0 auto;
  padding-top: 21px;
  @include media-query(phone, tablet) {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 230px;
    line-height: 20px;
  }
}

.benefitItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 105px;
  width: 100%;

  @include media-query(phone, tablet) {
    flex-direction: column;
    gap: 14px;
  }
}

.card {
  width: 250px;

  @include media-query(phone, tablet) {
    display: inline-flex;
    width: 340px;
  }
}

.highlightSectionWrapper {
  display: flex;
  justify-content: center;
}
.cardImage {
  @include media-query(phone, tablet) {
    width: 30px;
    height: 30px;
    margin-right: 18px;
  }
}

.cardHeading {
  font-size: 20px;
  padding-bottom: 0px;

  @include media-query(phone, tablet) {
    font-size: 18px;
    text-align: left;
  }
}

.cardText {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: basetica-thin;
    
  color: #515255;
  opacity: 1;
  @include media-query(phone, tablet) {
    font-size: 14px;
    text-align: left;
  }
}
