@import "../../../../styles/main.scss";

.container {
  display: flex;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 3px;
  cursor: pointer;

  .tag {
    background-color: #ffded7;
    border-radius: 4px;
    font-size: 7px;
    padding: 4px 7px;
    margin-right: 4px;
    font-family: basetica-regular;
  }

  .contentWrapper {
    padding: 16px;

    @include media-query(phone, tablet) {
      padding: 10px;
    }

    &__thin {
      padding: 10px;
    }
  }
  .heading {
    font-size: 14px;
    line-height: 19px;
    font-family: basetica-regular;
      
    margin-top: 7px;

    @include media-query(phone, tablet) {
      font-size: 10px;
      line-height: 13px;
    }
  }

  .description {
    font-size: 10px;
    line-height: 13px;
    margin-top: 5px;
    @include media-query(phone, tablet) {
      font-size: 9px;
      line-height: 10px;
    }
  }

  .readTime {
    font-size: 7px;
    line-height: 10px;
    font-family: basetica-regular;
      
    @include media-query(phone, tablet) {
      font-size: 6px;
      line-height: 6px;
    }
  }

  .contentWrapper {
    width: 70%;
  }

  .horizontalAlignCardImage {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 30%;
    img {
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  @include media-query(phone, tablet) {
    .cardImageContainer {
      width: 30%;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    .contentWrapper {
      width: 70%;
    }
  }
}

.verticalAlign {
  flex-direction: column;
  width: 220px;

  &__thin {
    max-width: 200px;
    min-height: 290px;
  }

  .cardImageContainer {
    height: 145px;

    img {
      border-radius: 6px;
    }
  }

  .contentWrapper {
    width: 100%;
  }

  .horizontalAlignCardImage {
    width: 100%;

    img {
      object-fit: contain;
      height: 100%;
      border-radius: 6px;
    }
  }
}
