@import "../../../styles/main.scss";

.heading {
  color: #515255;
  font-size: 40px;
  font-family: "basetica-bold";

  @include media-query(phone, tablet) {
    font-size: 28px;
    line-height: 34px;
  }
}
