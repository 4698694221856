@import "../../../../styles/main.scss";

.container {
  text-align: center;
  padding: 64px 0;
  @include media-query(phone, tablet) {
    padding: 30px 0;
    // padding-top: 60px;
  }
}

.heading {
  margin-bottom: 40px;
  color: $white-color;
  @include media-query(phone, tablet) {
    margin-bottom: 20px;
  }
}

.cardImageContainer {
  width: 54px;
  height: 54px;

  img {
    height: 100%;
  }

  @include media-query(phone, tablet) {
    width: 48px;
    height: 48px;
  }
}

.itemHeading {
  margin-top: 10px;
  margin-bottom: 7px;
  font-family: $bold;
  font-size: 18px;
  color: $white-color;
  @include media-query(phone, tablet) {
    font-size: 16px;
  }
}

.itemSubheading {
  font-family: $thin;
  font-size: 14px;
  line-height: 1.2;
  width: 190px;
  margin-top: 3px;
  color: $white-color;
  @include media-query(phone, tablet) {
    font-size: 14px;
    width: auto;
    max-width: 240px;
  }
}

.list-items {
  display: flex;
  // gap: 80px;
  // padding-bottom: 60px;
  margin: 0 auto;
  // max-width: 721px;
  width: 100%;
  justify-content: space-between;

  @include media-query(phone, tablet) {
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: 25% 25%;
    // gap: 24px;
    // row-gap: 40px;
    // width: auto;
  }
  @include media-query(phone) {
    // grid-template-columns: 40% 40%;
  }
}

.linkWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: $white-color;
  padding-top: 20px;
  gap: 5px;
  &:hover {
      text-decoration: none;
      color: $white-color;
    }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25%;
  width: 100%;
  padding: 15px;
  @include media-query(phone) {
    max-width: 50%;
  }
}

.link {
  gap: 5px;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @include media-query(phone, tablet) {
    padding-top: 20px;
  }
}
