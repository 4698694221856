// colors
$primary-color: #fd930d;

$white-color: #ffffff;

$black-color: #1C1C1D;
$plale-black-color: #363636;

$dark-grey-color: #515255;

$light-grey-color: #E5E5E5;

$green-color: #27C484;
$dark-green-color: #27c484;
$light-green-color: #e4f5ff;

$light-torques-color: #d2e5c7;

$light-cyan-color: #d2e5c7;

$red-color: #ff6464;
$light-red-color: #FDF7F0;
$light-pink-color: #fde5cb;
$mud-pink-color: #f9f3f1;
$beige-pink-color: #FFDED7;
$dark-beige-pink-color: #FDE5CB;

$orange-color: #ffb164;

$cyan-green-color: #86e9b9;
$slate-grey-color: #707070;


// fonts
$regular: "basetica-regular";
$bold: "basetica-bold";
$medium: "basetica-medium";
$thin: "basetica-thin";