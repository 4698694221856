@import "../../../../styles/main.scss";

.plan-table-wrap {
  background: $white-color;
  border-radius: 12px;
  // overflow: hidden;
  position: relative;
  margin-top: 60px;

  @include media-query(phone) {
    margin: 0 -15px;
    margin-top: 60px;

    .capsule-wrap {
      transform: scale(0.9);
    }
  }

  .upgarde-tag {
    top: -43px;
    position: absolute;
    left: 146px;
    z-index: 9;
    width: 171px;

    @include media-query(phone) {
      top: -33px;
      left: 8px;
      width: 92px;
      height: 31px;
      font-size: 10px;
    }
  }
}

.plan-name-wrap {
  display: flex;
  align-items: center;

  img {
    margin-right: 16px;

    @include media-query(phone) {
      margin-right: 10px;
    }
  }
}

.table-container {
  &.td-no-border {

    td,
    th {
      border: 0;
    }
  }

  &.td-size-small {
    td {
      padding: 8px 16px;

      @include media-query(phone) {
        padding: 8px;
      }
    }
  }

  &.thead-border {
    .MuiTableHead-root {
      th {
        border-bottom: 1px solid $cyan-green-color;

        &.recommended-plan {
          border-right: 2px solid $primary-color;
          border-left: 2px solid $primary-color;
          border-top: 2px solid $primary-color;
        }
      }
    }
  }

  &.tfooter-highlight {
    .MuiTableFooter-root {
      background: $light-green-color;

      th,
      td {
        padding: 16px;

        @include media-query(phone) {
          padding: 8px;
        }
      }

      td {
        &:nth-child(2) {
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }

  &.tbody-row-highlight {
    .MuiTableBody-root {
      tr:nth-child(even) {
        td {
          background: rgba($color: $light-grey-color, $alpha: 0.4);
        }
      }
    }
  }

  .MuiTableCell-alignCenter {
    img {
      margin: 0 auto;
    }
  }

  .status-check-img {
    width: 20px;
  }

  &.first-last-space {
    tr {
      >* {
        &:nth-child(1) {
          padding-left: 60px;

          @include media-query(tablet) {
            padding-left: 30px;
          }

          @include media-query(phone) {
            padding-left: 10px;
          }
        }

        &:nth-child(2) {
          border-right: 2px solid $primary-color;
          border-left: 2px solid $primary-color;

          @include media-query(tablet) {
            border-right: 2px solid $primary-color;
            border-left: 2px solid $primary-color;
          }

          @include media-query(phone) {
            border-right: 2px solid $primary-color;
            border-left: 2px solid $primary-color;
          }
        }

        &:nth-last-child(1) {
          padding-right: 60px;

          @include media-query(tablet) {
            padding-right: 30px;
          }

          @include media-query(phone) {
            padding-right: 10px;
          }
        }
      }
    }
  }

  th {
    font-size: 18px;
    font-family: $bold;
    color: $dark-grey-color;

    @include media-query(phone) {
      padding: 8px;
    }
  }

  td {
    font-size: 14px;
    font-family: $medium;
    color: $dark-grey-color;

    @include media-query(phone) {
      padding: 8px;
    }
  }

  .td-small {
    font-size: 12px;
    line-height: 1;
  }
}

.table-sticky-column1 {
  position: sticky;
  left: 0;
  background: $white-color;
  z-index: 1;

  &.MuiTableCell-footer {
    background: $light-green-color;
  }
}