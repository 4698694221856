@import "../../styles/main.scss";

.container {
  padding: 13px 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px #dedede;
  display: flex;
  justify-content: space-between;
  position: sticky;
  inset: 0;
  z-index: 3;
  width: 100%;
  align-items: center;

  @include media-query(phone, tablet) {
    padding: 14px 0;
    padding-right: 0;
  }
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-query(phone, tablet) {
    padding-right: 6px;
  }
}

.logoContainer,
.logo {
  object-fit: contain;
  height: 21px;
  width: 101px;
  cursor: pointer;
  @include media-query(phone, tablet) {
    height: 20px;
    width: 95px;
  }
}

.stripContainer {
  width: 100%; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
  overflow: hidden;
  background-color: #FDF7F0; /* Adjust color as needed */
  line-height: 30px; /* Adjust height as needed */
}

.strip {
  animation: rotateStrip 20s linear infinite; /* Adjust animation duration as needed */
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  @include media-query(phone, tablet) {
  animation: rotateStrip 15s linear infinite;
    /* Adjust animation duration as needed */
  }
}

.stripItem {
  white-space: nowrap;
  display: inline-block;
  margin-right: 10px; /* Adjust margin as needed */
  color: #1c1c1d;
  font-weight: bold;
}

@keyframes rotateStrip {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
 @include media-query(phone, tablet) {
  @keyframes rotateStrip {
      0% {
        transform: translateX(40%);
      }
  
      100% {
        transform: translateX(-100%);
  
      }
    }
 }
.navOptionsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  gap: 25px;

  .link {
    color: #515255;
    cursor: pointer;
  }

  .loginCta {
    gap: 4px;
  }

  .loginIcon {
    height: 12px;
    width: 10px;
  }

  .getQuoteCta {
    color: #ffffff;
    padding: 8px 24px;
    background: #fd930d;
    border-radius: 6px;
    font-size: 12px;
    border: 0px;
    outline: none;

    font-family: "basetica-regular";
    cursor: pointer;
  }

  @include media-query(phone, tablet) {

    .link,
    .loginCta,
    .loginIcon {
      display: none;
    }

    .getQuoteCta {
      padding: 12px 15px;
      font-size: 12px;
    }
  }
}

.hamburgerMenu {
  display: none;

  @include media-query(phone, tablet) {
    display: block;
  }
}

.mobileMenuItemContainer {
  display: none;

  @include media-query(phone, tablet) {
    display: block;
    position: absolute;
    width: 100%;
    top: 71px;
    left: 0px;
    height: 100vh;
    background: transparent;
    font-size: 14px;

    .mobileMenuItemContent {
      height: 316px;
      background: white;
      box-shadow: 0px 4px 10px #DEDEDE;
    }

    .pageLinkHandles {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 34px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
    }

    .socialHandles {
      border-top: 1px solid #ffb164;
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      gap: 30px;

      img {
        height: 26px;
        width: 26px;
      }
    }
  }
}