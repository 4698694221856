@import "../../../../styles/main.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #515255;
  margin-top: 79px;
  margin-bottom: 79px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    margin-top: 40px;
  }
}

.leftSection {
  width: 50%;
  @include media-query(phone, tablet) {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
  }
}
.rightSection {
  width: 50%;
}

.mwebCoverPhoto {
  display: none;
  @include media-query(phone, tablet) {
    display: block;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    img {
      object-fit: contain;
    }
  }
}
.dwebCoverPhoto {
  width: 100%;
  // height: 355px;

  img {
    object-fit: cover;
    width: 100%;
  }
  @include media-query(phone, tablet) {
    display: none;
  }
}

.starPoint {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "basetica-regular";
  color: #515255;
  margin-bottom: 13px;

  @include media-query(phone, tablet) {
    font-size: 16px;
    margin-bottom: 13px;
  }
}

.starRightText {
  margin-left: 12px;
    
}
.headerSection {
  @include media-query(phone, tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.leftSubheading {
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: 7px;
  font-family: basetica-thin;

  @include media-query(phone, tablet) {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.leftHeading {
  margin-top: 8px;
}

.bold {
  font-family: basetica-bold;
  text-decoration: dotted;
  font-weight: 100;
  border-bottom: 1px dotted #ffb164;
}

.learnMore {
  display: flex;
  font-size: 11px;
  gap: 5px;
  padding-top: 13px;
  align-items: center;

  img {
    height: 8px;
    width: 13px;
  }

  @include media-query(phone, tablet) {
    font-size: 14px;
    padding-top: 24px;
  }
}
