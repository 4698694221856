@import "../../../../styles/main.scss";

.container {
  padding-top: 38px;
  padding-bottom: 11px;
  text-align: center;
  background: #515255;

  @include media-query(phone, tablet) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.heading {
  font-size: 27px;
  padding-top: 9px;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
  font-family: "basetica-bold";
  max-width: 436px;
  color: #ffffff;
  margin: 0 auto;
  line-height: 32px;

  @include media-query(phone, tablet) {
    max-width: 100%;
    padding: 20px 0;
    font-size: 28px;
    line-height: 34px;
  }
}

.subHeading {
  font-size: 18px;
  font-family: $medium;
  line-height: 1.2;
  font-weight: 100;
  color: $white-color;
  opacity: 0.7;
  margin-top: 16px;

  @include media-query(phone, tablet) {
    font-size: 16px;
    line-height: 18px;
    padding: 0 40px;
    opacity: 1;
  }
}

.cta {
  margin-top: 10px;
  color: #ffffff;
  padding: 10px 18px;
  background: #fd930d;
  border-radius: 5px;
  font-size: 10px;
  border: 0;
  outline: none;
  font-family: "basetica-bold";
  cursor: pointer;
  font-weight: lighter;

  @include media-query(phone, tablet) {
    margin-top: 24px;
    right: 24px;
    font-size: 12px;
    padding: 16px 28px;
    border-radius: 6px;
  }
}
