@import "../../../styles/main.scss";

.container {
  font-size: 12px;
  line-height: 16px;

  @include media-query(phone, tablet) {
    font-size: 14px;
    line-height: 20px;
  }

  .bold {
      
    font-family: 'basetica-regular';
  }
}
