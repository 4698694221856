@import "../../../../styles/main.scss";

.container {
  color: #515255;
  padding: 52.26px 46.9px 6.7px 46.9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 16.08px;
    

  @include media-query(phone, tablet) {
    padding: 56px 20px 20px 20px;
    font-size: 14px;
    line-height: 18px;
  }

  .heading {
    font-family: basetica-bold;
    font-size: 44.22px;
    line-height: 53.6px;
    margin-top: 18.76px;
    margin-bottom: 32.16px;
    text-align: center;

    @include media-query(phone, tablet) {
      font-size: 30px;
      line-height: 40px;
      margin-top: 14px;
      margin-bottom: 28px;
    }
  }

  .item {
    padding-bottom: 16.08px;
    font-family: basetica-thin;
    line-height: 16.08px;
    font-size: 12.06px;
    
    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }

    .bold {
      font-family: basetica-regular;
    }
  }
}
