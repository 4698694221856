.text-wrap {
  h1 {
    font-size: 36px;
    line-height: 1.2;
    @include media-query(phone) {
      font-size: 28px;
    }
  }
  h2 {
    font-size: 30px;
    line-height: 1.2;
  }
  h3 {
    font-size: 28px;
    line-height: 1.2;
  }
  h5 {
    font-size: 24px;
    line-height: 1.2;
    @include media-query(phone) {
      font-size: 18px;
    }
  }
  .subtitle {
    font-size: 18px;
    line-height: 1.2;
    @include media-query(phone) {
      font-size: 16px;
    }
  }
  .subtitle1 {
    font-size: 16px;
    line-height: 1.2;
    @include media-query(phone) {
      font-size: 14px;
    }
  }
  .caption {
    font-size: 14px;
    line-height: 1.2;
    @include media-query(phone) {
      font-size: 12px;
    }
  }
  .small-caption {
    font-size: 12px;
  }
}