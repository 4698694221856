.plan-confirmation-layout {
  position: relative;
  z-index: 2;

  +.copyright-wrap {
    @include media-query(phone) {
      margin-bottom: 140px;
    }
  }
}

.plan-confirmation-wrap {
  display: flex;
  flex-wrap: nowrap;

  @include media-query(phone) {
    flex-wrap: wrap;
  }

  .plan-confirm-info-wrap {
    width: 60%;
    background: $white-color;
    border-radius: 6px;
    margin-right: 12px;
    overflow: hidden;

    @include media-query(phone) {
      width: 100%;
      margin-right: 0;
    }
  }

  .plan-cost-info-wrap {
    width: 40%;
    background: $white-color;
    border-radius: 6px;
    height: fit-content;
    margin-left: 12px;
    // overflow: hidden;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: $primary-color;
      top: 0;
      left: 0;
      position: absolute;
    }

    @include media-query(phone) {
      margin-top: 16px;
      margin-left: 0;
      width: 100%;
    }
  }
}

// plan details info section
.plan-info-row {
  padding: 24px;

  &:not(:last-child) {
    border-bottom: 1px dashed $light-grey-color;
  }

  .plan-info-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.plan-info-content-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  .text-wrap {
    &:last-child {
      width: 40%;
    }
  }
}


// plan cost breakdown section
.plan-cost-row {
  padding: 24px;

  &:not(:last-child) {
    border-bottom: 1px dashed $light-grey-color;
  }

  &.no-border {
    border-bottom: 0;
  }
}

.plan-cost-content-row {
  display: flex;
  justify-content: space-between;
  .plan-cost-info {
    display: flex;
    align-items: center;
    justify-content: center;
    .info-icon {
      width: 18px;
      opacity: 0.4;
    }
  }
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.referral-code-container {
  font-size: 12px;

  .referral-code {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    padding: 20px;
    margin: 20px 0px;

    .referral-code-input {
      border: 0px;
      outline: none;
      flex: 8;
    }

    .apply {
      color: #FD930D;
      cursor: pointer;
      flex: 2;
      text-align: right;
    }
  }

  .applied-referral-code-ui {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    padding: 8px 8px;
    margin: 20px 0px;

    .applied-referral-code-ui-left {
      flex: 9;
      display: flex;
      align-items: center;

      .applied-code-container {
        display: flex;
        align-items: center;
        background: rgba(39, 196, 132, 0.1);
        padding: 8px;
        border-radius: 4px;
        width: 100%;

        .applied-icon {
          color: #27c484;
        }

        .applied-text {
          font-size: 12px;
          display: flex;
          align-items: center;
        }

        .code {
          font-size: 12px;
          color: #404040;
          font-family: basetica-bold;
        }
      }
    }

    .applied-referral-code-ui-right {
      flex: 1;
      text-align: right;
    }
  }

  .referral-code-support-text {
    display: flex;
    gap: 4px;
    align-items: center;

    .referral-code-gift {
      color: #FD930D;
    }
  }

}

.plan-cost-structure-wrap {
  padding: 16px 24px;
  background: $black-color;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-query(phone) {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}