@import '../../styles/abstracts/variables';

.MuiList-root {
  padding: 0 !important;
}
.item-list {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &.MuiListItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }
  .MuiListItemAvatar-root {
    min-width: 30px;
    width: 30px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    img {
      width: 24px;
    }
  }
  .MuiListItemSecondaryAction-root {
    min-width: 30px;
    width: 30px;
    right: 12px;
  }
  .MuiListItemText-root {
    padding-right: 16px;
  }
  &.item-list-border {
    border-radius: 4px;
    border: 1px solid $light-grey-color;
  }
  &.item-invalid {
    position: relative;
    &:before {
      content: "";
      width: 3px;
      height: 20px;
      background: $red-color;
      position: absolute;
      left: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &.item-valid {
    position: relative;
    &:before {
      content: "";
      width: 3px;
      height: 20px;
      background: $green-color;
      position: absolute;
      left: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .list-action-wrap {
    cursor: pointer;
  }
}