.MuiAlert-root {
  &.MuiAlert-standardError {
    background: $red-color;
    .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action {
      color: $white-color;
    }
  }
  &.MuiAlert-standardSuccess {
    background: $green-color;
    .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action {
      color: $white-color;
    }
  }
}