@import "../../../../styles/main.scss";

.sectionContainer {
  background: white;
}

.container {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  padding: 46px 0px;
  color: #515255;

  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 68px 20px;
    gap: 30px;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
  }

  .icon {
    width: 35px;
    height: 32px;
  }

  .heading {
    font-family: basetica-bold;
    font-size: 24px;
    padding-top: 13px;
    padding-bottom: 7px;
    line-height: 24px;

    @include media-query(phone, tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .text {
    font-size: 12px;
  }
}
