@import "../../../../styles/main.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #515255;
  height: 512px;
  width: 563px;

  @include media-query(phone, tablet) {
    height: 180px;
    width: 195px;
  }

  .heroImage {
    width: 100%;
    height: auto;
  }
}
