.btn {
  outline: none;
  border: 0;
  font-weight: 400;
  cursor: pointer;
  &.btn-default {
    font-size: 14px;
    border-radius: 6px;
    padding: 16px;
  }
  &.btn-medium {
    font-size: 16px;
    border-radius: 12px;
    padding: 16px 24px;
  }
  &.btn-large {
    font-size: 20px;
    border-radius: 12px;
    padding: 16px 40px;
  }
  &.btn-bold {
    font-family: $bold;
  }

  &.btn-primary {
    color: $white-color;
    background: $primary-color;
    &:hover {
      background: rgba($color: $primary-color, $alpha: 1);
    }
  }
  &.btn-green {
    color: $white-color;
    background: $green-color;
    border: 1px solid $green-color;
    &:hover {
      background: rgba($color: $green-color, $alpha: 1);
    }
  }
  &.btn-primary-outline {
    color: $white-color;
    background: transparent;
    border: 1px solid $primary-color;
    outline: none;
    &:hover, &:focus, &:active {
      background: rgba($color: $primary-color, $alpha: 1);
      color: $white-color;
      border: 1px solid $primary-color;
    }
    &.black-color {
      color: $black-color;
    }
  }
  &.btn-black-outline {
    color: $black-color;
    background: transparent;
    border: 1px solid $black-color;
    outline: none;
    &:hover, &:focus, &:active {
      background: rgba($color: $black-color, $alpha: 1);
      color: $white-color;
      border: 1px solid $black-color;
    }
    &.no-hover {
      &:hover, &:focus, &:active { 
        background: transparent;
        color: $black-color;
      }
    }
  }

}