.toggle-btn-wrap {
  &.toggle-seperate-btn {
    .btn {
      border-radius: 6px !important;
      border: 0px;
      width: auto;
      box-shadow: none;
      letter-spacing: 0px;
      text-transform: inherit;
      height: 40px;
      padding: 12px 24px;
      font-family: $medium;
      text-transform: uppercase !important;
      color: $black-color !important; 
      &.Mui-selected, &:hover {
        color: $black-color; 
        box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
      }
      &.btn-outline {
        border: 1px solid $black-color;
        background: $white-color;
        &.Mui-selected, &:hover {
          color: $white-color !important;
          background: $black-color;
        }
      }
    }
    &.toogle-btn-small {
      .btn {
        padding: 12px;
        font-size: 14px;
        height: 32px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    .btn {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}