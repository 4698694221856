@import "../../../../../styles/main.scss";

.hero-container {
  height: calc(100vh - 58px);
  background: url("../../../../../assets/images/heroSection/webHeroSectionBuilding.svg") no-repeat;
  background-size: cover;
  background-position: left bottom;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.hero-mob-conatiner {
    @include media-query(phone) {
      background-image: none;
    }
  }
  @include media-query(phone, tablet) {
    text-align: center;
  }
  @include media-query(phone, tablet) {
    padding-top: 10%;
    height: auto;
  }
  .hero-left-wrap {
    width: 50%;
    @include media-query(phone, tablet) {
      width: 100%;
    }
  }
  .hero-right-wrap {
    width: 50%;
    @include media-query(phone, tablet) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .search-wrap {
    max-width: 320px;
    @include media-query(phone, tablet) {
      margin: 0 auto;
    }
    input {
      padding: 12px;
      width: auto;
    }
    button {
      padding: 12px;
    }
  }
}

.hero-wrap {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-query(phone, tablet) {
    flex-direction: column;
    margin-top: 15px;
  }
  &.mob-hero-swap-wrap {
    @include media-query(phone) {
      padding-bottom: 24px;
      // flex-direction: column-reverse;
      .hero-card-slider {
        margin-top: 30px;
      }
    }
  }
}

/* .hero-card-slider {
  overflow: hidden;
  max-width: 520px;
  margin: 0 auto;
  @include media-query(phone, tablet) {
    margin: 24px -15px 0;
  }
  .swiper-cards {
    .swiper-slide-shadow {
     display: none;
    }
    swiper-slide {
      filter: blur(2px);
      &.swiper-slide-active {
        filter: blur(0);
        .hero-card-wrap { 
          box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.4);
        }
      }
    }
    .hero-card-wrap { 
      @include media-query(phone) {
        max-width: 210px;
      }
      .hero-card-img {
        @include media-query(phone) {
          width: 90%;
        }
      }
    }
  }
}
.hero-card-wrap {
  background: $white-color;
  border-radius: 12px;
  box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.2);
  padding: 12px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 316px;
  width: 100%;
  margin: 30px auto;
  text-align: center;
} */

.hero-card-slider {
  overflow: hidden;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
  swiper-container {
    // max-width: 600px;
    height: 450px;
    position: relative;
    padding: 0 24px;
    @include media-query(phone, tablet) {
      padding: 0 24px;
      height: 300px;
    }
    @include media-query(phone) {
      padding: 0 12px;
    }
  }
  @include media-query(phone, tablet) {
    margin: 32px -15px 32px;
  }
  swiper-slide {
    // height: 400px;
    transform: scale(0.8);
    transition: all 200ms linear;
    position: relative;
    z-index: 0;
    filter: blur(1px);
    &.swiper-slide-prev {
      // transform: scale(0.8) translateY(20px);
      .hero-card-wrap { 
        box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.2);
      }
    }
    &.swiper-slide-next {
      // transform: scale(0.8) translateY(-20px);
      .hero-card-wrap { 
        box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.2);
      }
    }
    &.swiper-slide-active {
      transform: scale(1);
      z-index: 1;
      filter: blur(0);
      .hero-card-wrap { 
        transform-origin: center;
        box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.2);
      }
    }
    .hero-card-wrap {
      height: 100%;
      width: 100%;
      box-shadow: none;
    }
  }
  /* swiper-slide {
    filter: blur(2px);
    z-index: 0;
    position: relative;
    &.swiper-slide-active {
      z-index: 1;
      position: relative;
      filter: blur(0);
      .hero-card-wrap { 
        box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.4);
      }
    }
  } */
   
}
.hero-card-wrap {
  background: $white-color;
  border-radius: 12px;
  box-shadow: 0px 4px 22px rgba($color: $slate-grey-color, $alpha: 0.2);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // flex-wrap: wrap;
  // max-width: 316px;
  // margin: 30px auto;
  // flex-direction: column;
  width: 100%;
  text-align: center;
  .hero-card-img {
    // width: 40%;
    height: 100%;
    max-width: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .text-wrap {
    width: 60%;
    text-align: left;
  }
}