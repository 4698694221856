body {
  font-family: "basetica-regular";
  letter-spacing: 0.2px;
  background: #fdf7f0;
}

.body-container {
  max-width: 1024px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @media screen and (max-width: 1024px){
    max-width: 768px;
  }
  &.body-container-tablet-edge {
    @media screen and (max-width: 1024px){
      max-width: 768px;
      padding: 0;
    }
  }
}
.orderList {
  list-style-position: inside;
  &.alpha {
    list-style-type: lower-alpha;
  }
  &.roman {
    list-style-type: lower-roman;
  }
}
