.dialog-wrap {
  &.dialog-header-border {
    .card-header-wrap {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px dashed rgba($color: $slate-grey-color, $alpha: 0.5);
    }
  }
  &.MuiDialog-root {
    .MuiDialog-paper {
      width: 100%;
      @include media-query(phone) {
        position: absolute;
        bottom: 0;
        margin: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .card-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-wrap {
      width: calc(100% - 60px);
    }
    .btn-wrap {
      align-self: flex-start;
      margin-top: -4px;
      .MuiIconButton-root {
        img {
          width: 30px;
        }
      }
    }
  }
}