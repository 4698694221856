@import "../../../../styles/main.scss";

.container {
  background-color: #ffffff;
  padding: 30px 36px;
  margin: 20px 0;
  max-width: 620px;
  line-height: 20px;

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  @include media-query(phone, tablet) {
    margin: 20px 0;
    padding: 30px 27px;
  }
  .item {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
  }
  .icon {
    margin-right: 10px;
  }
  .label {
    font-size: 16px;
    font-family: "basetica-thin";
  }
  .text1 {
    font-size: 12px;
    font-family: "basetica-regular";
    line-height: 18px;
  }
  .text2 {
    font-size: 14px;
    font-family: "basetica-regular";
  }
}
