/* Media Query Mixin */
@mixin media-query($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @if $breakpoint == phone {
      @media only screen and (max-width: 767px) {
        @content;
      }
    } @else if $breakpoint == tablet {
      @media only screen and (min-width: 768px) and (max-width: 1279px) {
        @content;
      }
    } @else if $breakpoint == desktop {
      @media only screen and (min-width: 1280px) {
        @content;
      }
    }
  }
}
