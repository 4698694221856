@import "../../styles/main.scss";

.container {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #515255;
  padding: 47px 0;
  margin: 0 64px;
  gap: 6.7%;
  font-size: 12px;
  font-family: "basetica-thin";
  max-width: 854px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    align-items: center;
    grid-area: 0;
    padding: 44px 0;
    margin: 0 20px;
    font-size: 14px;
  }

  .heading {
    text-align: center;
    margin-top: 13px;
  }

  .seeMoreCta {
    background-color: #515255;
    padding: 10px 36px;
    color: #ffffff;
    border-radius: 8px;
    font-family: "basetica-regular";
    margin-top: 3px;
    outline: none;
    border: 0;

    @include media-query(phone, tablet) {
      margin-top: 4px;
      font-size: 14px;
      padding: 12px 30px;
      margin-bottom: 30px;
    }
  }
}
