@import "../../../../styles/main.scss";

.container {
  padding-top: 48px;
  // padding-left: 24px;
  // padding-right: 24px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin:  0 auto;
}

.heading {
  text-align: center;
  @include media-query(phone, tablet) {
    font-size: 28px;
    text-align: center;
  }
}

.textContainer {
  color: #1c1c1d;
  margin-top: 8px;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0.7;
}

.getQuoteContainer {
  position: relative;

  .getQuoteCTA {
    outline: none;
    border: 0;
    font-family: "basetica-bold";
    font-weight: 400;
    font-size: 20px;
    background: #fd930d;
    border-radius: 6px;
    padding: 18px 50px;
    color: #ffffff;
    cursor: pointer;
  }

  .mise {
    position: absolute;
    top: -60px;
    right: -60px;
  }
}


.poweredByContainer {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: basetica-bold;

  .poweredByHeading {
    color: #fd930d;
  }

  .poweredByText {
    color: #1c1c1d;
    opacity: 0.7;
  }

  @include media-query(phone, tablet) {
    font-size: 14px;
  }
}