.thankyou-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .thankyou-title-wrap {
    margin-bottom: 24px;
    img {
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .thankyou-info-wrap {
    background: $light-green-color;
    border-radius: 6px;
    padding: 24px;
    padding-bottom: 50px;
    margin: 0 24px;
    @include media-query(phone) {
      margin: 0;
    }
    .text-wrap {
      * {
        line-height: 1.4;
      }
    }
  }
  .declaration-title-wrap {
    margin: 0 24px;
    img {
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .thankyou-plan-detail-wrap {
    max-width: 400px;
    margin: 0 auto;
    .plan-card-wrap {
      cursor:  auto;
    }
  }
  .social-icon {
    width: 18px;
    vertical-align: middle;
    display: inline;
  }
  .btn-wrap {
    text-align: center;
    margin-top: -23px;
  }
}