@import "../../../../styles/main.scss";

.text {
  color: #515255;
  font-size: 18px;
  padding: 0px;
  text-align: center;
  opacity: 0.7;
  line-height: 24px;

  @include media-query(phone, tablet) {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }
}
