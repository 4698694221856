.employment-layout-container {
  position: relative;
  z-index: 2;
}

.plandetails-layout-container {
  position: relative;
  z-index: 2;

  +.copyright-wrap {
    margin-bottom: 140px;
    z-index: 0;
  }
}

.plan-amount-wrap {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 16px;

  .plan-amount-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0;
    background: $light-green-color;

    .plan-amount-info {
      max-width: 290px;
      padding: 12px 24px 12px 24px;

      @include media-query(phone) {
        max-width: 140px;
        padding: 12px 12px 12px 12px;
      }
    }

    .plan-amount-details {
      position: relative;
      padding: 12px 12px 12px 12px;

      @include media-query(phone) {
        width: calc(100% - 140px);
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        background: $orange-color;
        position: absolute;
        border-radius: 50%;
        left: -12px;
        top: 24px;
      }

      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: $orange-color;
        position: absolute;
        top: 24px;
        left: -6px;
      }
    }

    &:not(:first-child) {
      border-top: 1px dashed $light-grey-color;
      background: rgba($color: $light-green-color, $alpha: 0.6);
    }

    &:last-child {
      .plan-amount-details {
        &:after {
          display: none;
        }
      }
    }

    >div {
      width: 50%;
      padding: 0 12px;
    }
  }
}

.plan-list-wrap {
  max-width: 95%;

  @include media-query(phone) {
    max-width: 100%;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    @include media-query(phone) {
      flex-wrap: wrap;
    }

    li {
      width: calc(50% - 16px);
      margin-top: 8px;
      margin-bottom: 0 !important;

      @include media-query(phone) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-right: 12px;

        @include media-query(phone) {
          margin-right: 0;
        }
      }
    }
  }
}

.plan-fixed-footer-wrap {
  max-width: 1000px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  flex-direction: column;

  &.small-footer-wrap {
    max-width: 638px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;

    @include media-query(phone) {
      padding: 0;
    }
  }
}

.plan-details-continue-wrap {
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white-color;
  padding: 12px 24px;
  box-shadow: 0px 3px 20px #FFB16429;

  &.plan-details-noPrice-wrap {
    justify-content: center;

    .btn-wrap {
      max-width: 360px;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  @include media-query(phone) {
    padding: 12px;
  }
}

.plan-think-wrap {
  padding: 12px;
  background: $white-color;
  box-shadow: 0px 3px 20px #FFB16429;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .plan-think-icon {
    margin-right: 8px;
  }
}

.plan-details-card-wrap {
  display: flex;
  justify-content: space-between;


  @include media-query(phone, tablet) {
    max-width: 340px;
    margin: -22px auto 0;

    +.accordion-wrap {
      max-width: 340px;
      margin: 0 auto;

      .plan-list-wrap {
        max-width: 100%;

        ul {
          li {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  .plan-card-wrap {
    margin: 0 auto;
  }

  &.plan-details-card-3 {
    .plan-card-wrap {
      width: calc(33% - 12px);

      @include media-query(phone, tablet) {
        width: 100%;
      }
    }
  }

  &.plan-details-card-2 {
    .plan-card-wrap {
      width: calc(50% - 12px);

      @include media-query(phone, tablet) {
        width: 100%;
      }
    }
  }

  .benefits-info-wrap {
    min-height: 360px;
  }

  .plan-card-wrap {

    // 
    @include media-query(phone, tablet) {
      display: none;
      width: 100%;

      &.active {
        display: block;
      }
    }
  }
}

.plan-card-wrap {
  background: $light-pink-color;
  border: 2px solid transparent;
  position: relative;
  border-radius: 12px;
  .upgarde-tag {
    top: -44px;
    position: absolute;
    z-index: 9;

    @include media-query(tablet, phone) {
      top: 0;
      right: calc(-100% + 92px);
      width: 92px;
      height: 31px;
      font-size: 10px;
    }
  }

  &.plan-card-selection {
    cursor: pointer;

    &:hover,
    &.active {
      border: 2px solid $primary-color;
      box-shadow: 0px 4px 10px rgba($color: $black-color, $alpha: 0.4);

      &.low-box-shadow {
        box-shadow: 0px 4px 10px rgba($color: $black-color, $alpha: 0.15);
      }

      .plan-card-item {
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;

        .benefits-info-wrap {
          border-top: 2px solid $primary-color;
          box-shadow: 0px 4px 10px rgba($color: $black-color, $alpha: 0.6);
        }
      }
    }

    &.active {
      background: $beige-pink-color;
    }
  }
}

.plan-card-item {
  position: relative;

  .plan-info-wrap {
    text-align: center;
    padding: 16px;
    border-radius: 12px 12px 0 0;
  }

  .plan-capsule-mob-hide {
    @include media-query(tablet, phone) {
      display: none;
    }
  }

  .plan-selection-indicator {
    position: absolute;
    top: -3px;
    left: -3px;
    overflow: hidden;
    border-radius: 12px;
    transform: scale(1.5);
    transform-origin: left top;
    background: $white-color;

    img {
      width: 100%;
    }
  }

  .plan-price-wrap {
    position: relative;
    text-align: center;
    padding: 16px;
    background: $white-color;
    border-radius: 0 0 12px 12px;
    &:before {
      content: "";
      width: calc(100% - 32px);
      border-top: 1px dashed rgba($color: $slate-grey-color, $alpha: 0.5);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .plan-price-action-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .info-icon {
        width: 18px;
        opacity: 0.4;
      }
    }
  }
}

.benefits-info-wrap {
  padding: 16px;
  background: $white-color;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 2px solid transparent;

  .benefits-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .benefits-list-img {
      width: 20px;
      margin-right: 12px;
    }
  }

  .benefits-list-breakout {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    padding: 10px;
    border-radius: 6px;
    background: $light-red-color;
    > span {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      > span {
        &:last-child {
          width: calc(100% - 20px);
        }
      }
    }
    .bullet {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $light-torques-color;
      color: $black-color;
      margin-right: 6px;
    }
  }

  .coloum-wise-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 16px;

    .benefits-list-img {
      margin-right: 0;
      margin-top: 0px;
      margin-bottom: 12px;
    }

    .illustration-img {
      width: 143px;
      height: 143px;
    }
  }
}

.benefits-top-priority {
  .total-price {
    font-size: 34px;
    text-align: center;
    display: block;
    margin: 4px 0 0;
  }
}

.benefits-accordion-wrap {
  .benefits-accordion-header {
    margin: 0 -16px 10px;
    padding: 8px 16px;
    position: relative;
    padding-right: 24px;
    &.active {
      background: rgba($color: $light-grey-color, $alpha: 0.6);
      .accord-arrow-wrap {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    p {
      margin: 0;
    }
  }
  .accord-arrow-wrap {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    svg {
      opacity: 0.7;
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  .benefits-accordion-body {
    display: none;
    &.active {
      display: block;
    }
  }
}

.plan-tab-wrap {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
  background: $light-pink-color;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: none;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 20px;

  @include media-query(phone, tablet) {
    display: flex;
  }

  .plan-tab-item {
    padding: 12px 6px;
    width: 100%;

    @include media-query(phone) {
      padding: 8px 6px;
    }

    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &.active {
      background: $white-color;
    }

    &.active {
      .capsule-wrap {
        box-shadow: 0px 4px 10px rgba($color: $black-color, $alpha: 0.1);
      }
    }
  }

  .capsule-wrap {
    transform: scale(0.9);
  }
}