@import "../../../../styles/main.scss";

.container {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  @include media-query(phone, tablet) {
    padding: 30px 0 0 0;
  }
}

.heading {
  padding-top: 8px;
  padding-bottom: 33px;
  @include media-query(phone, tablet) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 20px;
  }
}

.accordion {
  width: 100%;
  border: none;
  margin-bottom: 0;
}

.accordion-item {
  margin-bottom: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: 0px;
  padding: 21px;
}

.card-header {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.accordion-body {
  margin: 0;
  padding: 0;
}

.seeMoreCta {
  background-color: #515255;
  padding: 11px 36px;
  color: #ffffff;
  border-radius: 8px;
  font-family: "basetica-regular";
  margin-top: 20px;
  outline: none;
  border: 0;
  margin-bottom: 53px;
  font-size: 13px;

  @include media-query(phone, tablet) {
    margin-top: 8px;
    font-size: 14px;
    padding: 12px 30px;
    margin-bottom: 20px;
  }
}
