@import "../../../../../../styles/main.scss";

.container {
  color: #515255;
  font-size: 18px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 45px;

  @include media-query(phone, tablet) {
    font-size: 16px;
    line-height: 20px;
  }
}

.planContainer {
  width: 100%;
  display: flex;
  gap: 16px;
}

.icon {
  height: 26px;
  width: 26px;
}
