@import "../../../../styles/main.scss";
.container {
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  padding: 60px 0px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 45px 20px;
  }
}

.heading {
  padding-top: 15px;
  font-size: 24px;
  @include media-query(phone, tablet) {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
  }
}

.left {
  flex: 1;
  @include media-query(phone, tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.right {
  flex: 1;

  .image {
    @include media-query(phone, tablet) {
      max-width: 620px;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
}

.cta {
  color: #ffffff;
  padding: 15px 32px;
  background: #fd930d;
  border-radius: 8px;
  font-size: 15px;
  border: 0px;
  outline: none;
  font-family: "basetica-bold";
  cursor: pointer;

  @include media-query(phone, tablet) {
    display: none;
  }
}
