@import "../../../../styles/main.scss";

.container {
  color: #515255;
  padding: 0 46.9px;
  font-family: basetica-thin;
  margin-bottom: 37.52px;
    

  @include media-query(phone, tablet) {
    padding: 0 20px;
    font-size: 14px;
    line-height: 24px;
  }

  .section {
    padding-top: 23.45px;
    font-size: 12.06px;
    line-height: 16.08px;

    @include media-query(phone, tablet) {
      padding-top: 20px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .itemWrapper {
    font-size: 12.06px;
    display: flex;
    margin-top: 13.4px;
  }

  .marginNumber {
    font-family: basetica-regular;
    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .sectionHeader {
    display: flex;
    align-items: center;
  }

  .sectionHeading {
    font-family: basetica-regular;
    font-size: 14.74px;
      
    margin-left: 10.72px;
    line-height: 18.76px;

    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .icon {
    position: relative;

    .img {
      height: 39.06px;
      width: 39.06px;
      @include media-query(phone, tablet) {
        height: 34px;
        width: 34px;
      }
    }
  }

  .number {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-family: "basetica-bold";
  }

  .text {
  }

  .description {
    margin-left: 49.58px;
    margin-top: 10.72px;
    @include media-query(phone, tablet) {
      margin-left: 5px;
    }
  }

  .list {
    margin-left: 49.58px;
    @include media-query(phone, tablet) {
      margin-left: 5px;
    }
  }

  .listItem {
    font-size: 12.06px;
    margin-left: 13.4px;

    @include media-query(phone, tablet) {
      margin-left: 12px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
