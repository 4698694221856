@import "../../../styles/main.scss";

.container {
  background: #ffb164 !important;
  color: #1c1c1d !important;
  letter-spacing: 2px !important;
  padding: 8px 19px !important;
  border-radius: 4px !important;
  display: inline-block !important;
  font-size: 9px !important;
  color: #515255 !important;
  font-family: basetica-regular !important;

  @include media-query(phone, tablet) {
    font-size: 10px !important;
  }
}
