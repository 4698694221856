@import "../../../../styles/main.scss";

.container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 20px 0;
  }
}

.exploreWhyCta {
  background: #ffb164;
  color: #1c1c1d;
  letter-spacing: 2.8px;
  padding: 12px 28px;
  border-radius: 6px;
  display: inline-block;
}

.mwebHeadingContainer {
  display: none;
  @include media-query(phone, tablet) {
    display: block;
  }
}

.dwebHeadingContainer {
  display: block;
  @include media-query(phone, tablet) {
    display: none;
  }
}

.headingContainer {
  padding-bottom: 30px;
  padding-top: 20px;
  text-align: center;
  line-height: 50px;
  width: 100%;
  .headingTwo {
    color: #fd930d;
  }

  @include media-query(phone, tablet) {
  line-height: 30px;
    font-size: 28px;
    padding-bottom: 30px;
  }
}

.exploreWhyItems {
  display: flex;
  justify-content: space-between;
  // gap: 30px;
  width: 100%;
  > * {
    max-width: 33%;
    width: 100%;
    padding: 0 15px;
    @include media-query(phone) {
      max-width: 100%;
    }
  }

  @include media-query(tablet) {
    // max-width: 600px;
  }
  @include media-query(phone) {
    // gap: 0;
    max-width: 100%;
    flex-direction: column;
    max-width: 270px;
    > div {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      img {
        padding-bottom: 0;
        width: 60px;
        height: auto;
        margin-right: 30px;
      }
    }
  }
}

.cardText {
  font-family: $medium;
  font-size: 16px;
  color: $dark-grey-color;

  @include media-query(phone, tablet) {
    font-size: 16px;
  }
}

.cardImage {
  padding-bottom: 25px;
  height: 104px;
  width: 104px;
  margin: 0 auto;

  @include media-query(phone, tablet) {
    padding-bottom: 25px;
  }
}

.cardHeading {
  font-size: 18px;
  color: $dark-grey-color;
  padding: 0;

  @include media-query(phone, tablet) {
    font-size: 18px;
  }
  @include media-query(phone) {
    text-align: left;
  }
}
