
@import "./../../styles/abstracts/mixins";
@import "./../../styles/abstracts/variables";

.layout-background {
    // background-image: url('../../assets/images/Get_a_Quote_BG_2.svg');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: 100% 50%;
    position: relative;
    &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 220px;
        height: 220px;
        background-image: url('../../assets/images/layoff.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.2;
        z-index: 0;
    }
    &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 360px;
        background-image: url('../../assets/images/building.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        opacity: 0.2;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 3;
    }
}


.layout-bg-color {
    min-height: 100vh;
    background-color: #FDF7F0;
}

.bg-color-transparent {
    background: transparent !important;
}

.layout-footer {
    position: absolute;
    bottom: 0;
    margin-bottom: 24px;
    color: #515255;
}

.layout-conatiner {
    // max-width: 960px;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
    min-height: calc(100vh - 114px);
    padding: 24px 0;
    display: flex;
    align-items: center;
    @include media-query(phone) {
        align-items: flex-start;
        min-height: calc(100vh - 174px);
    }
}

.small-container {
    max-width: 638px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    @include media-query(phone) {
        padding: 0;
    }
}
.medium-container {
    max-width: 848px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    @include media-query(phone) {
        padding: 0;
    }
}
.large-container {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    @include media-query(phone) {
        padding: 0;
    }
}

@import "./styles/header";
@import "./styles/card";
@import "./styles/form";
@import "./styles/chip";
@import "./styles/typography";
@import "./styles/toogle-button";
@import "./styles/accordion";
@import "./styles/dialog";

@import "./styles/auth";
@import "./styles/plan-details";
@import "./styles/plan-confirmation";
@import "./styles/thank-you";
@import "./styles/alert";