@import "../../../../styles/main.scss";

.container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  @include media-query(phone, tablet) {
    padding: 0 16px;
  }
}

.heading {
  padding: 20px;
  @include media-query(phone, tablet) {
    font-size: 28px;
  }
}

.founders {
  display: flex;
  gap: 17px;

  @include media-query(phone, tablet) {
    flex-direction: column;
  }
}

.founder {
  flex: 1;
  background: white;
  padding: 32px;
  gap: 11px;
  box-shadow: 0px 2px 13px #ffb16429;
  text-align: left;

  @include media-query(phone, tablet) {
    padding: 26px;
  }

  .photo {
    border-radius: 50%;
    height: 137px;
    width: 137px;
    @include media-query(phone, tablet) {
      height: 114px;
      width: 114px;
    }
  }

  .name {
    font-size: 24px;
    font-family: basetica-bold;
    color: #515255;
    padding-top: 20px;

    @include media-query(phone, tablet) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .designation {
    font-size: 16px;
    color: #fd930d;
    font-family: basetica-bold;
    padding-top: 5px;
    @include media-query(phone, tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .linkIcon {
    cursor: pointer;
    @include media-query(phone, tablet) {
      width: 14px;
      height: 14px;
    }
  }

  .socialHandles {
    padding-top: 5px;
    color: #8e8e8e;
    filter: grayscale(1);
  }

  .about {
    font-family: basetica-thin;
      
    padding-top: 11px;
    color: rgba(81, 82, 85, 0.8);
    font-size: 12px;
    line-height: 19px;

    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
