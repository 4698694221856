@import "../../../../styles/main.scss";

.container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 20px 0;
  }
}

.exploreWhyCta {
  background: #ffb164;
  color: #1c1c1d;
  letter-spacing: 2.8px;
  padding: 12px 28px;
  border-radius: 6px;
  display: inline-block;
}

.headingContainer {
  padding-bottom: 8px;
  padding-top: 20px;
  text-align: center;
  line-height: 50px;
  width: 100%;
  .headingTwo {
    color: #fd930d;
  }

  @include media-query(phone, tablet) {
  line-height: 30px;
    font-size: 28px;
    padding-bottom: 30px;
  }
}
