@import "../../../styles/main.scss";

.container {
  background: #55cd6c;
  box-shadow: 0px 4px 10px #00000029;
  border-radius: 50%;
  opacity: 1;
  position: fixed !important;
  top: 80%;
  transform: translateY(-80%);
  padding: 15px;
  right: 20px;
  z-index: 9 !important;;
  @include media-query(phone) {
    z-index: 3;
    padding: 10px;
  } 
}

.wrapper {
  z-index: 5;
}

.container:hover {
  transform: scale(1.1) translate(0, -60%)
}

.image {
  height: 40px;
  width: 40px;
  @include media-query(phone, tablet) {
    height: 24px;
    width: 24px;
  } 
}
