@import "../../../../../styles/main.scss";

.container {
  background: #ffffff;
  padding: 3px;
  box-shadow: 0px 3px 7px #00000029;
  border-radius: 8px;
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;

  @include media-query(phone, tablet) {
    margin: 0 auto;
    border-radius: 6px;
  }
}

.input {
  border: 0px;
  background: #ffffff;
  font-size: 12px;
  width: 268px;
    
  color: #515255;
  outline: none;
  border: none;
  padding: 18px 25px;
  width: 250px;
  display: block;
  font-family: basetica-regular;
  letter-spacing: 0;

  &::placeholder {
    color: #363636;
    opacity: 0.54;
  }

  &[type='text'] {
    font-size: 12px;
  }

  @include media-query(phone, tablet) {
    width: 225px;
    padding: 14px;
    font-size: 12px;
    &[type='text'] {
      font-size: 12px;
    }
  }
}



.cta {
  color: #ffffff;
  padding: 16px 32px;
  background: #fd930d;
  border-radius: 8px;
  font-size: 15px;
  border: 0px;
  outline: none;
  font-family: "basetica-bold";
  cursor: pointer;

  @include media-query(phone, tablet) {
    right: 24px;
    font-size: 12px;
    padding: 16px 24px;
    border-radius: 6px;
  }
}
