@import "../../../../styles/main.scss";

.container {
  height: 320px;
  @include media-query(phone, tablet) {
    height: 270px;
  }
}

.cardWrapper {
  background: #ffffff;
  border-radius: 6px;
  padding: 17px;
  margin-left: 6px;
  margin-right: 6px;
  color: #515255;
  font-family: basetica-regular;
  width: 144px;
  text-align: left;
  box-sizing: border-box;
  position: relative;

  @include media-query(phone, tablet) {
    padding: 15px;
    height: auto;
    width: 130px;
  }
}

.topCard {
  margin-bottom: 8px;
}

.cardDate {
  padding-top: 3px;
  font-size: 7px;
  opacity: 0.8;
    
}

.employeesCnt {
  padding-top: 12px;
  font-size: 30px;
  font-family: basetica-bold;
  @include media-query(phone, tablet) {
    font-size: 30px;
    padding-top: 8px;
  }
}

.employeesTextWrapper {
  display: flex;
  align-items: center;
}

.employeesText {
  padding-left: 3px;
  font-size: 11px;

  @include media-query(phone, tablet) {
    font-size: 10px;
  }
}

.location {
  padding-top: 27px;
  font-size: 7px;
  @include media-query(phone, tablet) {
    padding-top: 16px;
  }
}

.evenCard {
  margin-top: 8px;
}

.logoContainer {
  height: 40px;
  width: 83px;

  img {
    height: 100%;
    object-fit: contain;
  }

  @include media-query(phone, tablet) {
    height: 30px;
    width: 75px;
  }
}

.disclaimerText {
  max-width: 855px;
  padding-left: 47px;
  font-size: 11px;
  color: #f9f3f1;
  text-align: left;
  font-family: basetica-thin;
    
  @include media-query(phone, tablet) {
    font-size: 12px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
