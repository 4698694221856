@import "../../../../../styles/main.scss";

.container {
  padding-top: 10%;
  
  font-size: 21px;
  height: calc(100vh - 58px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-query(phone, tablet) {
    padding-top: 15%;
    height: calc(100vh - 70px);
    background-image: none;
    align-items: stretch;
  }
}

.bgContainer {
  background-image: url("../../../../../assets/images/heroSection/webHeroSection.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include media-query(phone, tablet) {
    background-position: left bottom;
  }
}

.headingContainer {
  text-align: center;
  font-size: 44px;
  font-family: "basetica-bold";
  color: #515255;
  line-height: 47px;
  margin-bottom: 14px;

  .highlight {
    color: #fd930d;
  }

  @include media-query(phone, tablet) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 14px;
  }
}

.subHeading {
  margin: 0 auto;
  font-size: 15px;
  color: #1c1c1d;
  opacity: 0.7;
  padding-top: 4px;
  text-align: center;
  width: 459px;
  padding-bottom: 34px;
  line-height: 20px;
  @include media-query(phone, tablet) {
    font-size: 14px;
    width: 277px;
  }
}

.mobileHomePageBannerContainer {
  display: none;
  @include media-query(phone, tablet) {
    height: 100%;
    display: block;
    background-image: url("../../../../../assets/images/heroSection/mwebHeroSection.svg");
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    margin: 0 -15px;
  }
}

.mobileHomePageBanner {
  height: 100%;
  object-fit: cover;
}