@import "../../styles/main.scss";

.dashboard-content-wrap {
  padding: 24px 34px;
  width: calc(100% - 260px);
  margin-left: auto;
  @include media-query(phone, tablet) {
    padding: 15px;
    width: 100%;
    margin-top: 70px;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
}

.dash-content-header {
  padding: 12px 0;
  margin-bottom: 24px;
  border-bottom: 1px dashed $light-grey-color;
  img {
    height: 30px;
    @include media-query(phone) {
      height: 24px;
    }
  }
} 


.dasboard-connect-wrap {
  @include media-query(phone) {
    margin-bottom: 24px;
  }
  &.dasboard-connect-end-wrap {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
  }
  .connect-large-img {
    width: 44px;
    margin-bottom: 12px;
    @include media-query(phone) {
      .connect-large-img {
        width: 24px;
        margin-bottom: 8px;
      }
    }
  }
  .connect-small-img {
    width: 24px;
    margin-bottom: 8px;
  }
}

.plan-illust-wrap {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}