@import "../../../../styles/main.scss";


.mental-health-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  .mental-health-left-wrap {
    width: 40%;
    @include media-query(phone, tablet) {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
      justify-content: center;
      align-items: center;
      .link-wrap {
        justify-content: center;
      }
    }
  }
  .mental-health-right-wrap {
    width: 60%;
    @include media-query(phone, tablet) {
      width: 100%;
    }
  }
}

.mental-health-info-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 0;
  @include media-query(phone, tablet) {
    align-items: flex-start;
    justify-content: center;
    gap: 0;
    margin-top: 44px;
  }
  .m-info-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 48px;
    @include media-query(phone, tablet) {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      width: 50%;
      padding: 0 16px;
      align-items: center;
      .health-heading {
        text-align: center;
      }
    }
  }
}

.m-title-wrap {
  @include media-query(phone, tablet) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
  }
}

.health-heading {
  font-size: 16px;
  color: $dark-grey-color;
  padding: 0;
  text-align: left;
  line-height: 1.3;
  text-align: left;

  @include media-query(phone, tablet) {
    font-size: 14px;
  }
}

.m-infographic-wrap {
  position: relative;
  @include media-query(phone, tablet) {
    max-width: 500px;
    margin: 0 auto;
  }
  @include media-query(phone) {
    max-width: fit-content;
    margin: 0 -15px;
    width: calc(100% + 60px);
  }
  p {
    font-size: 20px;
    position: absolute;
    left: 60%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 100%;
    text-align: center;
    @include media-query(phone, tablet) {
      font-size: 14px;
      bottom: -20px;
      left: 50%;
    }
  }
}