@import "../../styles/main.scss";

.sidenav-wrap {
  position: fixed;
  background: $white-color;
  width: 260px;
  box-shadow: 0px 4px 10px $light-grey-color;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;

  > {
    width: 100%;
  }

  @include media-query(phone, tablet) {
    width: 100%;
    height: auto;

    &.active {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: none;
    }
  }
}

.sidenav-bacdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  background: fixed;
  backdrop-filter: blur(4px);
  background: rgba($color: $black-color, $alpha: 0.5);
  width: 100%;
  display: none;

  @include media-query(phone, tablet) {
    display: block;
  }
}

.sidenav-navigation-wrap {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-query(phone, tablet) {
    justify-content: space-between;
    max-width: 768px;
    margin: 0 auto;
    padding: 14px 15px;
  }
}

.side-logo-wrap {
  width: 200px;
  margin: 0 auto;
  padding: 24px 0;
  cursor: pointer;

  @include media-query(phone, tablet) {
    height: 20px;
    width: 95px;
    padding: 0;
    margin: 0;
  }
}

.sidenav-divider-wrap {
  padding: 12px 0;
  border-top: 1px dashed $light-grey-color;
  border-bottom: 1px dashed $light-grey-color;

  @include media-query(phone, tablet) {
    padding: 6px 0;
    margin: 6px 0;
  }
}

.sidenav-header-list-wrap,
.sidenav-footer-list-wrap {
  @include media-query(phone, tablet) {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    display: none;

    &.active {
      display: block;
    }
  }
}

.side-nav-list {
  li {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    font-size: 14px;
    font-family: $medium;
    padding: 8px 24px;
    min-height: 40px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    color: $black-color;

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }

    &.active,
    &:hover {
      background: $light-red-color;

      &::after {
        content: "";
        width: 4px;
        height: 20px;
        background: $primary-color;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      object-fit: contain;
    }
  }

  &.side-nav-inlinelist {
    display: flex;
    justify-content: space-around;

    @include media-query(phone, tablet) {
      justify-content: flex-start;

      li {
        margin-right: 14px;
      }
    }

    li {
      padding: 8px 8px 8px 16px;
      position: relative;
      width: auto;

      &::after {
        display: none;
      }

      &:not(:first-child) {
        &:before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: $dark-beige-pink-color;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.active,
      &:hover {
        background: $white-color;

        a {
          color: $primary-color
        }
      }
    }
  }
}


.sidenav-popover-action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 14px;
  color: $black-color;
  max-width: 240px;

  .avtar-wrap {
    margin-right: 14px;
    background: $beige-pink-color;
    color: $black-color;
  }

  .popover-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 60px);

    .action-name-wrap {
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 10px;
      transform: rotate(270deg);
    }
  }
}

.sidenav-popover-list {
  .MuiPaper-root {
    width: 230px;
  }
}

.sidenav-hamburger-wrap {
  display: none;

  @include media-query(phone, tablet) {
    display: block;
  }
}