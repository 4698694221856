.container {
  padding: 20px 24px;
}

.heading {
  font-size: 40px;
  color: #515255;
  font-family: basetica-bold;
  padding: 20px 0px;
}

.formWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.btn {
  border-radius: 6px !important;
}

.btnContainer {
  padding-bottom: 20px;
}