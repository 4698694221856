@import "../../../styles/main.scss";

.container {
  display: inline-block;
  .icon {
    position: relative;
    .img {
      height: 58px;
      width: 58px;
      @include media-query(phone, tablet) {
        height: 41px;
        width: 41px;
      }
    }
  }

  .number {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-family: "basetica-bold";

    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
