@import "../../../styles/main.scss";

.container {
  padding: 20px 24px;
}

.heading {
  font-size: 32px;
  color: #515255;
  font-family: basetica-bold;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-query(phone, tablet) {
    font-size: 24px;
  }

  p {
    flex: 9;
  }

  img {
    flex: 1;
    cursor: pointer;

    height: 32px;
    width: 32px;

    @include media-query(phone, tablet) {
      height: 24px;
      width: 24px;
    }
  }
}

.referralInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  .dash {
    height: 1px;
    width: 50%;
    background: red;
    border: none;
  }
}

.benefits {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .benefit {
    display: flex;
    font-size: 16px;

    .left {
      flex: 1;

      .leftContainer {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 12px;
        padding-top: 0;
        justify-content: center;
        color: #FD930D
      }

    }

    .right {
      flex: 9;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .highlight {
        font-family: basetica-bold;
      }

      .highlightInfo {
        font-size: 14px;
        line-height: 18px;
        padding-left: 18px;
        padding-top: 6px;

        .highlightInfoList {
          list-style-type: disc;
        }

        .highlightInfoList li {
          margin-bottom: 10px;

          @include media-query(phone, tablet) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.rewards {
  font-size: 14px;

  margin: 30px 0px;
  padding: 10px 2px;
  display: flex;
  gap: 4px;
  align-items: center;
  background: rgba(253, 147, 13, 0.1);

  @include media-query(phone, tablet) {
    font-size: 12px;
  }

  .rewardIcon {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FD930D;
  }

  .rewardText {
    line-height: 18px;
    flex: 9;
    font-family: basetica-bold;
  }
}


.tnc {
  margin: 12px;
  color: grey;
  font-size: 12px;
  text-decoration: underline !important;
  text-align: right;
}