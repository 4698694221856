@import "../../../../styles/main.scss";

.container {
  color: #515255;
  padding: 0 47px;
  font-family: basetica-thin;
  margin-bottom: 94px;
  font-size: 12px;
  line-height: 16px;

  @include media-query(phone, tablet) {
    padding: 0 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .header {
    display: flex;
    align-items: center;

    @include media-query(phone, tablet) {
      margin-bottom: 20px;
    }
  }

  .section {
    margin: 19px 0;
  }

  .itemWrapper {
    display: flex;
    margin-top: 16px;

    &--nospace {
      margin-top: 0;
    }
  }

  .marginNumber {
    font-family: basetica-regular;

    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .sectionHeading {
    font-size: 15px;
    font-family: basetica-regular;
      
    margin-left: 11px;
    line-height: 19px;
    width: 80%;
    
    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .description {
    margin-left: 50px;
    margin-bottom: 11px;

    &--nospace {
      margin-bottom: 0;
    }

    @include media-query(phone, tablet) {
      margin-left: 0;
    }
  }

  .footer {
    margin-left: 50px;
    margin-bottom: 11px;
    margin-top: 11px;

    &--nospace {
      margin-bottom: 0;
    }

    @include media-query(phone, tablet) {
      margin-left: 0;
    }
  }

  .list {
    margin-left: 50px;

    @include media-query(phone, tablet) {
      margin-left: 0;
    }
  }

  .listItem {
    margin-left: 20px;

    @include media-query(phone, tablet) {
      margin-left: 10px;
    }
  }

  .starIconOrderImg {
    height: 39px !important;
    width: 39px !important;
  }
}
