@import '../../styles/abstracts/variables';

.loader-wrap {
  width: 100%;
  height: 100%;
  background: $white-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.MuiCircularProgress-root {
  &.MuiCircularProgress-colorPrimary {
    color: $primary-color;
  }
}