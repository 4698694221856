@font-face {
  font-family: "basetica-regular";
  src: url("../../assets/font/basetica-regular.ttf") format("truetype");
}

@font-face {
  font-family: "basetica-bold";
  src: url("../../assets/font/basetica-bold.ttf") format("truetype");
}

@font-face {
  font-family: "basetica-medium";
  src: url("../../assets/font/basetica-medium.ttf") format("truetype");
}

@font-face {
  font-family: "basetica-thin";
  src: url("../../assets/font/basetica-thin.ttf") format("truetype");
}
