@import "../../../../styles/main.scss";

.container {
  padding: 67px 0;
  @include media-query(phone, tablet) {
    padding: 48px 0px;
  }
}

.heading {
  text-align: center;
}

.coveredItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // max-width: 721px;
  margin: auto;
  padding-top: 34px;
  box-sizing: border-box;
  // gap: 54px;

  @include media-query(phone, tablet) {
    // gap: 24px 54px;
    margin: 0 -15px;
  }
}

.logo {
  @include media-query(phone, tablet) {
    width: auto;
    height: 75px;
  }
}

.coveredItemsLastRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 721px;
  margin: auto;
  padding-top: 34px;
  box-sizing: border-box;
  gap: 80px;

  @include media-query(phone, tablet) {
    max-width: 720px;
  }
}

.coveredItem {
  // width: 184px;
  color: #515255;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-width: 275px;
  padding: 15px;
  width: 100%;

  @include media-query(phone) {
    // max-width: 140px;
    max-width: 50%;
  }
}

.itemHeading {
  padding-top: 11px;
  padding-bottom: 4px;
  font-family: $bold;
  font-size: 18px;
  text-align: center;
  line-height: 1.2;

  @include media-query(phone, tablet) {
    font-size: 16px;
  }
}

.itemSubheading {
  padding-top: 5px;
  font-family: $thin;
  font-size: 14px;
  text-align: center;
  line-height: 1.2;

  @include media-query(phone, tablet) {
    font-size: 14px;
  }
}

.link {
  gap: 5px;
  text-decoration: none;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-grey-color;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @include media-query(phone, tablet) {
    padding-top: 20px;
  }
}

.learnMore {
  display: flex;
  align-self: center;
  font-size: 11px;
  gap: 5px;
  padding-top: 13px;
  align-items: center;
  color: $dark-grey-color;

  &:hover {
    color: $dark-grey-color;
  }

  img {
    height: 8px;
    width: 13px;
  }

  @include media-query(phone, tablet) {
    font-size: 14px;
    padding-top: 24px;
  }
}
