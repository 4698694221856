.heading {
  font-size: 14px;
  color: #fd930d;
  padding-bottom: 8px;
}

.button {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  gap: 2px;
  border: 1px solid #E5E5E5 !important;
  border-radius: 3px;
  color: #363636 !important;
  background: #f9f3f1 !important;
  text-transform: none !important;
}