.header-wrap {
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  padding: 16px 24px;
  @include media-query(phone) {
    max-width: 100%;
  }
} 

.nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include media-query(phone) {
    flex-direction: column;
    align-items: start;
  }
  .navigate-btn-wrap {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    @include media-query(phone) {
      .btn {
        margin-bottom: 16px;
      }
      position: static;
      transform: none;
    }
  }
}

.logo-wrap {
  width: 200px;
}
