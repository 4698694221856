@import "../../../../styles/main.scss";
.container {
  color: #515255;
  padding: 52px 47px 7px 47px;
  font-size: 12px;
  line-height: 16px;

  @include media-query(phone, tablet) {
    padding: 56px 20px 0px 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .highLightHeading {
    font-size: 7px;
    line-height: 7px;
  }

  .heading {
    font-family: basetica-bold;
    font-size: 44px;
    line-height: 54px;
    margin-top: 19px;
    margin-bottom: 32px;
    text-align: center;

    @include media-query(phone, tablet) {
      font-size: 30px;
      line-height: 40px;
      margin-top: 14px;
      margin-bottom: 28px;
    }
  }

  .description {
    margin-bottom: 9px;
    font-family: basetica-thin;
  }

  .item {
    padding-bottom: 16px;
    font-family: basetica-thin;
    &.bold {
        
    }
  }
}
