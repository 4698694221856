@import '../../styles/abstracts/variables';

.MuiButton-root,
.MuiButtonBase-root {
  &.btn {
    box-shadow: none;
    font-size: 16px;
    border-radius: 12px;
    letter-spacing: 0px;
    text-transform: inherit;

    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2)
    }

    &.btn-primary {
      background: $primary-color;
      text-transform: inherit;
      color: $white-color;

      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.8);
      }

      .MuiCircularProgress-root {
        color: $white-color;
      }
    }

    &.btn-white {
      background: $white-color;
      color: $black-color;

      .MuiCircularProgress-root {
        color: $black-color;
      }
    }

    &.btn-light-torques {
      background: $light-torques-color;
      text-transform: inherit;

      &:hover {
        background: rgba($color: $light-torques-color, $alpha: 0.8);
      }
    }

    &.btn-light-pink {
      background: $light-pink-color;
      text-transform: inherit;

      &:hover {
        background: rgba($color: $light-pink-color, $alpha: 0.8);
      }
    }

    &.btn-green {
      background: $green-color;
      text-transform: inherit;
      color: $white-color;

      &:hover {
        background: rgba($color: $green-color, $alpha: 0.8);
      }

      .MuiCircularProgress-root {
        color: $white-color;
      }
    }

    &.btn-upgrade {
      background: $plale-black-color;
      text-transform: inherit;
      color: $white-color;
      width: 150px;

      &:hover {
        background: rgba($color: $plale-black-color, $alpha: 0.8);
      }

      .MuiCircularProgress-root {
        color: $white-color;
      }
    }

    &.MuiButton-text {
      &:hover {
        box-shadow: none;
        background: none;
      }

      &.btn-black {
        color: $black-color;

        .MuiCircularProgress-root {
          color: $black-color;
        }
      }
    }


    &.MuiButton-sizeLarge {
      height: 46px;
      font-size: 18px;

      .MuiButton-startIcon {
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }

    &.MuiButton-sizeMedium {
      font-size: 14px;

      .MuiButton-startIcon {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.MuiButton-sizeSmall {
      height: 36px;
      font-size: 14px;
      border-radius: 24px;
      padding: 8px 22px;

      .MuiButton-startIcon {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .MuiCircularProgress-root {
      margin-left: 8px;
    }
  }
}