@import "../../../../styles/main.scss";

.container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 20px;
  }
}

.exploreWhyCta {
  background: #ffb164;
  color: #1c1c1d;
  letter-spacing: 2.8px;
  padding: 12px 28px;
  border-radius: 6px;
  display: inline-block;
}

.mwebHeadingContainer {
  display: none;
  @include media-query(phone, tablet) {
    display: block;
  }
}

.dwebHeadingContainer {
  display: block;
  @include media-query(phone, tablet) {
    display: none;
  }
}

.headingContainer {
  padding-bottom: 30px;
  padding-top: 20px;
  text-align: center;
  line-height: 50px;

  .headingTwo {
    color: #fd930d;
  }

  @include media-query(phone, tablet) {
  line-height: 30px;
    font-size: 28px;
    padding-bottom: 30px;
  }
}

.exploreWhyItems {
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    gap: 30px;
  }
}

.cardText {
  font-family: basetica-thin;
    
  font-size: 16px;

  @include media-query(phone, tablet) {
    font-size: 16px;
  }
}

.cardImage {
  padding-bottom: 25px;
  height: 104px;
  width: 104px;
  margin: 0 auto;

  @include media-query(phone, tablet) {
    padding-bottom: 25px;
  }
}

.cardHeading {
  font-size: 18px;
  color: #515255;

  @include media-query(phone, tablet) {
    font-size: 18px;
  }
}
