@import "../../../styles/main.scss";

.dash-plan-card {
  border-radius: 12px;
  background: $dark-beige-pink-color;

  .plan-header-wrap {
    padding: 24px 32px;
    background: $dark-beige-pink-color;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    min-height: 127px;
    display: flex;

    @include media-query(phone) {

      .btn-download-action,
      .download-plan-action-text {
        display: none;
      }

      padding: 16px;
      text-align: center;
      padding-bottom: 24px;
      margin-bottom: -12px;
    }

    &.active {
      background: $beige-pink-color;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .plan-header-action-wrap {
        align-items: center;
        justify-content: center;
      }
    }

    &.iconic-plan {
      justify-content: center;
      flex-direction: column;
    }
  }

  &.selected-card {
    border: 2px solid $primary-color;
    box-shadow: 0 4px 10px #1c1c1d1a;
    background-color: $beige-pink-color;

    .plan-footer-wrap {
      background-color: $white-color;
      border-top: 2px dotted $light-grey-color;
    }

    .plan-body-wrap {
      border-top: 2px solid $primary-color;
    }
  }

  .plan-body-wrap {
    padding: 24px 32px;
    background: $white-color;
    min-height: 345px;

    &.plan-body-radius-wrap {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &.plan-body-top-radius-wrap {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .wrap-heading {
      font-style: italic;
      line-height: 1.4;
      opacity: .7;
      font-weight: 500;
    }

    @include media-query(phone) {
      padding: 16px;
    }
  }

  .plan-footer-wrap {
    padding: 24px 32px;
    border-top: 1px solid $light-red-color;
    background: $beige-pink-color;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    @include media-query(phone) {
      padding: 16px;
    }
  }
}

.btn-download-action-mb {
  display: none;

  @include media-query(phone) {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

.plan-header-action-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media-query(phone) {
    justify-content: center;

    button {
      display: none;
    }
  }
}

.plan-date-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;

  .text-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    p {
      margin-left: 6px;
    }
  }
}

.plan-footer-action-wrap {
  display: flex;
  justify-content: space-between;

  &.w-100 {
    width: 100%;
  }
}

.plan-body-footer-wrap {
  @include media-query(phone) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 10px #00000029;
  }
}

.plan-price-details {
  .text-wrap {
    display: flex;
    align-items: flex-start;
  }

  button {
    margin-top: -8px;
  }

  .info-icon {
    width: 18px;
    opacity: 0.4;
  }
}

.plan-slider-wrap {
  max-width: 240px;
  margin: 0 auto;

  .plan-slider-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      width: 200px;
    }
  }
}

.dashboard-plan-master {
  display: flex;

  @include media-query(tablet, phone) {
    flex-direction: column;
  }
}

.existing-plan-item {
  flex: 1;
  margin-right: 20px;

  @include media-query(tablet, phone) {
    width: 100%;
    margin-right: 0;
  }
}

.upgarde-plan-item {
  width: 40%;
  position: relative;

  @include media-query(tablet, phone) {
    width: 100%;
    margin-top: 36px;
  }
}

.upgarde-tag {
  background: $plale-black-color;
  width: 153px;
  height: 44px;
  font-size: 14px;
  color: $white-color;
  text-align: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 2px solid $primary-color;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  left: 0;
  right: 0;

  @include media-query(phone) {
    position: static;
    margin-top: 0;
  }
}