@import "../../styles/main.scss";

.containerWrapper {
  background: rgba(81, 82, 85, 1);
  color: #ffffff;
  z-index: 2;
  position: relative;
}

.footer {
  &.body-container {
    @include media-query(phone) {
      padding: 0;
    }
  }
}

.container {
  max-width: unset;
  text-align: left;
}

.body {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  padding-top: 30px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    padding: 0;
  }

  .leftBodyContainer {
    width: 50%;

    .logoContainer {
      height: 30px;
      width: 237px;

      img {
        height: 100%;
      }

      @include media-query(phone, tablet) {
        height: 28px;
        width: 237px;
      }
    }

    .info {
      padding: 18px 0;
      font-size: 12px;
      max-width: 370px;
      line-height: 20px;

      @include media-query(phone, tablet) {
        padding-top: 20px;
        padding-bottom: 28px;
        width: 100%;
      }
    }

    @include media-query(phone, tablet) {
      width: 100%;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 0px;
    }
  }

  .rightBodyContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 18px;

    .socialHandles {
      display: flex;
      gap: 20px;
      justify-content: flex-end;

      svg {
        cursor: pointer;
      }

      .socialHandle {
        height: 30px;
        width: 30px;
      }

      @include media-query(phone, tablet) {
        .socialHandle {
          height: 30px;
          width: 30px;
        }
      }
    }

    .otherPageUrls {
      display: flex;
      gap: 30px;
      justify-content: flex-end;
    }

    @include media-query(phone, tablet) {
      flex-direction: column-reverse;
      gap: 28px;
      width: 100%;
      padding-bottom: 0px;

      .otherPageUrls {
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
        padding: 0px 15px;
      }

      .socialHandles {
        justify-content: flex-start;
        border: 0.5px solid #ffded7;
        border-left: 0px;
        border-right: 0px;
        padding: 20px 15px;
      }
    }
  }
}

.link {
  font-size: 12px;
  cursor: pointer;

  @include media-query(phone, tablet) {
    font-size: 12px;
  }
}

.footer {
  border-top: 0.5px solid #ffded7;
  justify-content: space-between;
  font-size: 12px;
  display: flex;
  padding: 20px 0;

  .footerUrls {
    gap: 20px;
    display: flex;

    @include media-query(phone, tablet) {
      gap: 12px !important;
      font-size: 12px;
      padding: 20px 15px;
      border-bottom: 0.5px solid #ffded7;

      .link {
        font-size: 12px;
      }
    }
  }

  .copyRight {
    @include media-query(phone, tablet) {
      font-size: 12px;
      padding: 20px 15px;
    }
  }

  @include media-query(phone, tablet) {
    font-size: 18px;
    border-top: none;
    flex-direction: column-reverse;
    padding: 0px;
  }
}

.designedBy {
  font-size: 12px;
  border-top: 0.5px solid #ffded7;
  padding: 20px 0;
  text-align: left;
  display: flex;
  gap: 4px;
  align-items: center;
  @include media-query(phone, tablet) {
    text-align: left;
    font-size: 12px;
    padding: 20px 15px;
    gap: 8px;
  }
}
