@import "../../../../styles/main.scss";

.container {
  color: #515255;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
  padding-top: 50px;

  @include media-query(phone, tablet) {
    flex-direction: column;
    padding-top: 54px;
  }
}

.stepsHeadingText {
  margin-top: 13px;
  margin-bottom: 39px;

  @include media-query(phone, tablet) {
    margin-bottom: 24px;
  }
}

.rightSection {
  padding-left: 40px;
  width: calc(100% - 300px);
  .mwebRightSection {
    display: none;
  }

  .rightInnerSection {
    max-width: 480px;
    width: 100%;
  }

  @include media-query(phone, tablet) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    .rightInnerSection {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .dwebRightSection {
      display: none;
    }

    .mwebRightSection {
      max-width: 100%;
      display: block;
    }
  }
}

.step {
  display: flex;
  align-items: center;
  max-width: 485px;
  margin-bottom: 19px;

  @include media-query(phone, tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 13px;
  }
}

.stepHeading {
  font-size: 15px;
  font-family: "basetica-bold";
  margin-bottom: 5px;

  @include media-query(phone, tablet) {
    font-size: 18px;
  }
}

.stepSubheading {
  font-size: 12px;
  font-family: basetica-thin;
  line-height: 16px;

  @include media-query(phone, tablet) {
    font-size: 14px;
    text-align: center;
  }
}

.stepRightSection {
  margin-left: 19px;

  @include media-query(phone, tablet) {
    margin: 16px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.howItWorksImageContainer {
  display: block;
  width: 300px;
  // height: 498px;

  img {
    height: 100%;
  }

  @include media-query(phone, tablet) {
    display: none;
  }
}

.howItWorksImageContainerRightSection {
  display: none;

  @include media-query(phone, tablet) {
    display: block;
    // height: 241px;
    width: 245px;
    margin-bottom: 15px;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.stepNo {
  position: relative;
  left: 7px;
  left: -23px;
  font-size: 15px;
  font-family: "basetica-bold";
}

.getQuote {
  padding: 15px 27px;
  font-size: 13px;
    
  color: #ffffff;
  background: #fd930d;
  border-radius: 8px;
  display: inline-flex;
  margin-top: 17px;
  cursor: pointer;
  font-family: basetica-bold;

  @include media-query(phone, tablet) {
    display: none;
  }
}
