@import "../../../../styles/main.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #515255;
  padding-top: 52px;

  @include media-query(phone, tablet) {
    padding-top: 56px;
  }

  .heading {
    margin-top: 19px;

    @include media-query(phone, tablet) {
      font-size: 30px;
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }
}
