@import './display';
@import './flex';
@import 'spacer';
@import 'text';
@import 'widthAndHeight';
@import './button';

.link-wrap {
  display: flex;
  align-self: center;
  font-size: 11px;
  gap: 5px;
  padding-top: 13px;
  align-items: center;
  color: $dark-grey-color;

  &:hover {
    color: $dark-grey-color;
  }

  img {
    height: 8px;
    width: 13px;
  }

  @include media-query(phone, tablet) {
    font-size: 14px;
    padding-top: 24px;
  }
}
