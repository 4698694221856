/* Text utilities */
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-family-regular {
  font-family: $regular !important;
}

.font-family-bold {
  font-family: $bold !important;
}

.font-family-medium {
  font-family: $medium !important;
}

.font-family-thin {
  font-family: $thin !important;
}

.black-color {
  color: $black-color;
}

.primary-color {
  color: $primary-color;
}

.white-color {
  color: $white-color !important;
}

.green-color {
  color: $green-color !important;
}

.dark-grey-color {
  color: $dark-grey-color !important;
}

.plale-black-color {
  color: $plale-black-color;
}

.bg-black-color {
  background-color: $black-color;
}

.bg-mud-pink-color {
  background-color: $mud-pink-color;
}

.bg-white-color {
  background-color: $white-color;
}

.bg-dark-grey-color {
  background-color: $dark-grey-color !important;
}

.bg-mud-pink-color {
  background-color: $mud-pink-color !important;
}

.bg-beige-pink-color {
  background-color: $beige-pink-color !important;
}

.bg-light-torques-color {
  background-color: $light-torques-color !important;
}

.bg-light-cyan40-color {
  background-color: rgba($color: $light-cyan-color, $alpha: 0.4) !important;
}

.bg-light-green-color {
  background-color: $light-green-color !important;
}

.dark-grey-color {
  color: $dark-grey-color;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.visibility-none {
  opacity: none;
  visibility: hidden;
}

.heading {}

.subheading {
  font-size: 16px;
  line-height: 1.4;

  @include media-query(phone, tablet) {
    font-size: 14px;
  }
}

.underline {
  text-decoration: underline;
}