@import "../../styles/main.scss";

.container {
  // max-width: 1280px;
  padding: 60px 0;
  margin: 0 auto;
  text-align: center;
  @include media-query(phone, tablet) {
    padding: 30px 0;
  }
}

.heading {
  padding-top: 28px;
  padding-bottom: 48px;

  @include media-query(phone, tablet) {
    padding-bottom: 28px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
