.chip {
  &.MuiChip-root {
    border-radius: 6px;
    height: 30px;
    @include media-query(phone) {
      height: 26px;
    }
    .MuiChip-label {
      font-size: 14px;
      @include media-query(phone) {
        font-size: 12px;
      }
      letter-spacing: 1px;
      line-height: 1;
    }
    &.MuiChip-sizeLarge {
      height: 36px;
      .MuiChip-label {
        font-size: 14px;
      }
    }
    &.MuiChip-sizeSmall {
      height: 24px;
      .MuiChip-label {
        font-size: 12px;
      }
    }
    &.chip-primary {
      background: $primary-color;
      color: $black-color;
    }
    &.chip-orange {
      background: $orange-color;
      color: $black-color;
    }
    &.chip-light-torques {
      background: $light-torques-color;
      color: $black-color;
    }
    &.chip-black {
      background: $black-color;
      color: $white-color;
    }
    &.letter-space-2 {
      .MuiChip-label {
        letter-spacing: 2px;
      }
    }
  }
}