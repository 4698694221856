@import "../../../../styles/main.scss";

.container {
  padding-top: 53px;
  padding-bottom: 53px;
  max-width: 1280px;
}

.heading {
  padding-top: 12px;
  padding-bottom: 24px;
}

.swiperContainer {
  max-width: 1000px;
  // @include media-query(phone, tablet) {
  //   padding: 0px 20px;
  // }
}
