@import "../../../../../styles/main.scss";

.container {
  box-shadow: 1px 1px 1px #00000029;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  background: white;
  text-align: left;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    height: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    gap: 7px;
    align-items: center;

    .photo {
      height: 40px;
      width: 40px;

      img {
        border-radius: 50%;
      }
    }

    .personalInfo {
      font-family: basetica-bold;
      font-size: 16px;
      color: #363636;
      opacity: 0.8;
    }

    .socialShareIconWrapper {
      height: 12px;
      width: 12px;
    }
  }

  .body {
    margin-top: 13px;
    font-family: basetica-thin;
    line-height: 24px;
    overflow-y: auto;
    height: 40vh;
    @include media-query(phone, tablet) {
      font-size: 14px;
      line-height: 20px;
      overflow-y: auto;
      height: 36vh;

    }
    
  }

  @media screen and (min-width: 1400px) and (max-width: 1450px),
  screen and (min-height: 850px) {
    .body {
        margin-top: 13px;
        font-family: basetica-thin;
        line-height: 24px;
        overflow-y: auto;
        height: 32vh;
      }
  }

  .footer {
    display: flex;
    gap: 3px;
    align-items: center;
    font-family: basetica-thin;

    .likesIconContainer {
      height: 10px;
      width: 45px;
      display: inline-flex;
      align-items: center;

      img {
        height: 24px;
        width: 24px;
      }

      img:not(:first-child) {
        margin-left: -7px;
      }
    }
  }

  @include media-query(phone, tablet) {
    width: 100%;
    height: 405px;
  }
}
