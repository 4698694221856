@import "../../styles/main.scss";

.container {
  display: flex;
  flex-wrap: nowrap;
  color: #515255;
  padding: 60px 0;
  margin: 0;
  gap: 6.7%;
  font-size: 12px;
  font-family: "basetica-thin";
  justify-content: center;
  align-items: center;
  width: 100%;

  @include media-query(phone, tablet) {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    grid-area: 0;
    padding: 44px 0;
    // margin: 0 20px;
    font-size: 14px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-query(phone, tablet) {
      align-items: center;
    }
  }

  .highLightHeading {
    margin-top: 23.45px;

    @include media-query(phone, tablet) {
      margin-top: 35px;
    }
  }

  .heading {
    margin-top: 12.06px;
    margin-bottom: 20.1px;

    @include media-query(phone, tablet) {
      font-size: 30px;
      padding: 0 20px;
      text-align: center;
    }
  }
}
