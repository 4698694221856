@import "../../../../styles/main.scss";

.container {
  color: #515255;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media-query(phone, tablet) {
    padding: 0 16px;
  }
}

.header {
  margin-top: 59px;
  margin-bottom: 21px;
  font-family: basetica-bold;
  font-size: 44px;
  width: 545px;
  line-height: 53px;

  @include media-query(phone, tablet) {
    line-height: 36px;
    font-size: 28px;
    width: 100%;
  }
}

.subHeader {
  font-size: 15px;
  margin-bottom: 18px;
  line-height: 21px;
  font-weight: 600;
  @include media-query(phone, tablet) {
    font-size: 14px;
    line-height: 18px;
  }
}

.protectedBuildingContainer {
  height: 238px;
  img {
    height: 100%;
    width: 100%;
  }
  @include media-query(phone, tablet) {
    height: 100%;
  }
}
