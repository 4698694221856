.card {
  padding: 24px;
  background: $white-color;
  border-radius: 6px;
  border-color: $white-color;
  border-width: 0;
  &.card-large {
    padding: 50px;
    @include media-query(phone) {
      padding: 24px;
    }
    .dotted-border {
      margin-top: 24px;
      position: relative;
      &:before {
        content: "";
        width: calc(100% + 100px);
        left: -50px;
        height: 1px;
        border-top: 1px dashed $light-grey-color;
        position: absolute;
        top: 0;
        @include media-query(phone) {
          width: calc(100% + 48px);
          left: -24px;
        }
      }
    }
    .card-highlight-wrap {
      &.card-highlight-right {
        &:before {
          content: "";
          height: 100%;
          width: 50px;
          right: -50px;
          top: 0;
          background: $light-green-color;
          position: absolute;
          @include media-query(phone) {
            width: 24px;
            right: -24px;
          }
        }
      }
      &.card-highlight-left {
        &:before {
          content: "";
          height: 100%;
          width: 50px;
          left: -50px;
          top: 0;
          background: $light-green-color;
          position: absolute;
          @include media-query(phone) {
            width: 24px;
            left: -24px;
          }
        }
      }
    }
  }
}
.card-shadow {
  box-shadow: 0px 3px 20px #FFB16429;
}
.card-highlight-wrap {
  padding: 24px;
  background: $light-green-color;
  position: relative;
  display: flex;
  align-items: center;
  @include media-query(phone) {
    padding: 16px;
  }
  &.card-highlight-right {
    border-radius: 6px 0 0 6px;
    &:before {
      content: "";
      height: 100%;
      width: 24px;
      right: -24px;
      top: 0;
      background: $light-green-color;
      position: absolute;
    }
  }
  &.card-highlight-left {
    border-radius: 0 6px 6px 0;
    padding-left: 0;
    &:before {
      content: "";
      height: 100%;
      width: 24px;
      left: -24px;
      top: 0;
      background: $light-green-color;
      position: absolute;
    }
  }
}
.card-header-wrap {
  margin-bottom: 24px;
}
.card-footer-wrap {
  padding-top: 24px;
}
.card-btn-end-wrap {
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    .btn {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}