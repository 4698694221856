@import "../../../../styles/main.scss";

.mentors-gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .mentor-wrap {
    border-radius: 12px;
    width: calc(33% - 16px);
    margin: 16px 8px 0;
    @include media-query(tablet) {
      width: calc(50% - 16px);
    }
    @include media-query(phone) {
      width: 100%;
    }
  }
}

.mentors-gallery-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .btn {
    margin: 8px;
  }
}