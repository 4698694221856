.auth-mobile-edit {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: $light-red-color;
  padding-left: 8px;
  .text-wrap {
    padding-right: 16px;
    margin-right: 8px;
    border-right: 1px solid $light-grey-color;
  }
}

.auth-timer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-wrap {
    padding-left: 16px;
  }
}

.otp-input-wrap {
  border: 1px solid $light-grey-color;
  border-radius: 5px;
  &.valid {
    border: 1px solid $green-color;
    input {
      border-right: 1px solid $green-color;
    }
    ~ .helper-text {
      color: $green-color;
    }
  }
  &.invalid {
    border: 1px solid $red-color;
    input {
      border-right: 1px solid $red-color;
    }
    ~ .helper-text {
      color: $red-color;
    }
  }
  input {
    width: 25% !important;
    height: 60px;
    border-right: 1px solid $light-grey-color;
    font-size: 24px;
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0px 5px 5px 0px;
      border-right: 0;
    }
    &:focus {
      outline: none;
    }
  }
  ~ .helper-text {
    font-style: italic;
    p {    
      img {
        display: inline;
        vertical-align: sub;
        width: 16px;
      }
    }
  } 
}