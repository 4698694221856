@import "../../../../styles/main.scss";

.mentor-slider-wrap {
  // max-width: 1226px;
  margin: 0 auto;
  width: 100%;
  swiper-slide {
    height: auto;
    .mentor-wrap {
      height: 100%;
    }
  }
  @include media-query(phone) {
    max-width: 560px;
  }
}

.mentor-wrap {
  border-radius: 12px;
  overflow: hidden;
  .mentor-item {

  }
  .mentor-image-wrap {
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: transparent linear-gradient(345deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.01) 40%) 0% 0% no-repeat padding-box;  
      mix-blend-mode: multiply;
      opacity: 0.8;
    }
    img {
      border-radius: 12px;
      width: 100%;
    }
  }
  .mentor-info-wrap {
    padding: 16px 24px;
    position: relative;
    .mentor-title {
      color: $dark-grey-color;
      font-size: 22px;
      font-family: $bold;
    }
    .mentor-subtitle {
      color: $dark-grey-color;
      font-size: 16px;
      font-family: $thin;
      opacity: 0.7;
    }
    .mentor-caption {
      color: $dark-grey-color;
      font-size: 16px;
      font-family: $medium;
      opacity: 0.7;
    }
    .mentor-experience {
      position: absolute;
      top: -24px;
      right: 16px;
      color: $white-color;
      font-size: 14px;
      font-family: $medium;
    }
    .mentor-social {
      a {
        position: absolute;
        top: 0;
        right: 16px;
        background: $primary-color;
        padding: 8px 8px 8px 8px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 1;
      }
      img {
        width: 20px;
      }
    } 
  }

}