@import "../../../styles/main.scss";

.accordion {
  width: 100%;
  font-size: 15px;
  color: #515255;
  line-height: 19px;
  text-align: left;
  font-family: basetica-thin;

  @include media-query(phone, tablet) {
    font-size: 14px;
    line-height: 18px;
  }
}

.accordion-item {
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 21px;
  background: #ffffff;

  @include media-query(phone, tablet) {
    padding: 18px;
  }
}

.accordion-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.accordion-title {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: basetica-regular;
    
  width: 85%;
}

.accordion-content {
  overflow: hidden;
  padding-top: 12px;
  transition: max-height 0.5s ease;
  font-family: basetica-thin;
}

.accordion-icon {
  background: #d2e5c7;
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-query(phone, tablet) {
    height: 34px;
    width: 34px;
  }
}

/* Additional CSS */
.accordion-content p {
  margin-bottom: 7px;
}




.accordion-content ul {
  margin-bottom: 7px;
  padding-left: 18px;
  
  li {
    list-style-type: disc;
    margin-bottom: 5px;
  }
}

.show-more-cta {
  color: #ffffff;
  padding: 8px 24px;
  background: #fd930d;
  border-radius: 4px;
  font-size: 9px;
  border: 0px;
  outline: none;
    
  font-family: "basetica-regular";
  cursor: pointer;

  @include media-query(phone, tablet) {
    padding: 9px 15px;
    font-size: 12px;
  }
}
